import request from "../core/request";

export default {
  paymentAll() {
    return request.execute("get", "admin/payment/type/all/v2");
  },
  addDepartmentPayment(payload) {
    return request.execute(
      "post",
      "admin/department/add/payment/type",
      payload
    );
  },
  deleteDepartmentPayment(payload) {
    return request.execute(
      "delete",
      `admin/department/delete/payment/type/${payload.departmentId}/${payload.paymentTypeId}`
    );
  },
};
