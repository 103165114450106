import api from "@/api";
const name = "PENALTY";
const namespaced = true;
const state = {
  allTypes: [],
};
const getters = {
  STATE: (state) => {
    return state.allTypes;
  },
};
const mutations = {
  SET_ALL_PENALTY: (state, data) => {
    state.allTypes = data;
  },
};
const actions = {
  GET_ALL_PENALTY: async ({ commit }) => {
    let data = await api.Penalty.getAllPenaltyTypes();
    commit("SET_ALL_PENALTY", data);
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
