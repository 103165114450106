import request from "../core/request";

export default {
  getId() {
    return request.execute("get", "admin/auth/token");
  },
  getUserInfo(id) {
    return request.execute("get", `admin/user/${id}`);
  },
  userAll(id) {
    return request.execute("get", `/admin/user/all/${id.page}/${id.pageSize}`);
  },
  deleteUser(id) {
    return request.execute("delete", `admin/user/delete/${id}`);
  },
  addUser(input) {
    return request.execute("post", "admin/user/add", input);
  },
  editUser(input) {
    return request.execute("put", "admin/user/update", input);
  },
  getUser(id) {
    return request.execute("get", `admin/user/${id}`);
  },
  getPowers() {
    return request.execute("get", "admin/user/powers");
  },
};
