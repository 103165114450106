import request from "../core/request";

export default {
  getCurrency(id) {
    return request.execute("get", `/admin/currency/${id}`);
  },
  addCurrency(input) {
    return request.execute("post", `/admin/currency/add`, input);
  },
};
