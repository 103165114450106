import request from "../core/request";

export default {
  createBanner(input) {
    return request.execute("post", "admin/banner/add", input);
  },
  addImageForBanner(input) {
    return request.execute(
      "post",
      "admin/banner/add/image?" +
        `bannerId=${input.bannerId}&` +
        `desktopImageName=${input.desktopImageName}&` +
        `mobileImageName=${input.mobileImageName}`,
      input.file,
      {
        "Content-Type": "multipart/form-data",
      }
    );
  },
  getBannerList(departmentId) {
    return request.execute(
      "get",
      `admin/banner/get/all/byDepartmentId/${departmentId}`
    );
  },
  getBannerImage(input) {
    return request.execute(
      "get",
      `admin/banner/get/image/${input.bannerId}/${input.type}`
    );
  },
  deleteBanner(bannerId) {
    return request.execute("delete", `admin/banner/delete/${bannerId}`);
  },
  updateSeveralBanners(input) {
    return request.execute("put", "admin/banner/edit/several", input);
  },

  addTypeToBanner(payload) {
    return request.execute(
      "post",
      `admin/banner/add/type/${payload.bannerId}/${payload.typeId}`
    );
  },
  addCategoryToBanner(body) {
    return request.execute(
      "put",
      `admin/banner/editCategoryOrDepartment`,
      body
    );
  },
  addProductToBanner(payload) {
    return request.execute(
      "post",
      `admin/banner/add/bannerProduct/${payload.bannerId}/${payload.productId}`
    );
  },
  // баннера для категорий
  getCategoryBanners(cityId) {
    return request.execute("get", `admin/banner/get/categoryBanners/${cityId}`);
  },
  createCategoryBanner(payload) {
    return request.execute("post", `admin/banner/add/category/banner`, payload);
  },
  bindBannerWithCategory(payload) {
    return request.execute(
      "post",
      `admin/banner/link/category/banner`,
      payload
    );
  },
  changeBannerVisibility(bannerId) {
    return request.execute("get", `admin/banner/published/${bannerId}`);
  },
};
