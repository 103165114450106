import request from "../core/request";

export default {
  getDeletedProducts(page) {
    return request.execute(
      "get",
      `/admin/provider/product/get/deleted/${page}/50`
    );
  },
  // body:[708679, 708682]
  restoreProducts(body) {
    return request.execute("put", `/admin/provider/product/restore`, body);
  },
  // body: {"name": "string",
  //   “page“: 0,
  //   “size”: 50 }
  findDeletedProducts(body) {
    return request.execute(
      "post",
      `admin/provider/product/find/deletedProduct`,
      body
    );
  },
};
