import api from "@/api";
const name = "SCHEDULE";
const namespaced = true;
const state = {
  shiftList: [],
  pickerSlots: [],
  courierSlots: [],
  pickersPlan: [],
  couriersPlan: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_STATE: (state, data) => {
    (state.shiftList = data.shifts),
      (state.pickerSlots = data.pickerShiftSlots),
      (state.courierSlots = data.courierShiftSlots);
  },
  SET_PLANS: (state, data) => {
    state.pickersPlan = data.picker;
    state.couriersPlan = data.courier;
  },
};
const actions = {
  SHIFT_LIST: async ({ commit }, payload) => {
    let data = await api.Schedule.shiftList(payload);
    commit("SET_STATE", data);
  },
  SHIFT_ADD: async ({ commit }, payload) => {
    let data = await api.Schedule.shiftAdd(payload);
    return data;
  },
  SCHEDULE_LIST: async ({ commit }, payload) => {
    let data = await api.Schedule.scheduleList(payload);
    let shift = [];
    const commitData = {};
    data.data.forEach((elem) => {
      shift.push({
        id: elem.id,
        name:
          "С " +
          elem.timeBegin +
          " до " +
          elem.timeEnd +
          ", перерывы " +
          elem.breakAmountPlan +
          " раз(-a) по " +
          elem.breakTimePlan +
          " минут ",
        ...elem,
      });
    });
    commitData.courier = shift.filter(
      (element) => element.role.name === "Курьер"
    );
    commitData.picker = shift.filter(
      (element) => element.role.name === "Сборщик"
    );
    commit("SET_PLANS", commitData);
  },
  SHIFT_UPDATE: async ({ commit }, payload) => {
    let data = await api.Schedule.shiftUpdate(payload);
    return data;
  },
  SHIFT_DELETE: async ({ commit }, payload) => {
    let data = await api.Schedule.shiftDelete(payload);
    return data;
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
