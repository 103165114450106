/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
import Vue from "vue";

const name = "ASTOR";
const namespaced = true;

const state = {
  files: [],
  skuPricesChanges: [],
  productCatalogChanges: [],
};

const getters = {
  STATE: (state) => {
    return state;
  },
};

const mutations = {
  // TODO: еще нужно продумать условие ошибки, потому что это неправильная проверка
  SET_FILES: (state, data) => {
    if (data) {
      state.files = data;
    }
  },
  SET_SKU_PRICES_CHANGES: (state, data) => {
    if (data) {
      state.skuPricesChanges = data;
    }
  },
  SET_PRODUCT_CATALOG_CHANGES: (state, data) => {
    if (data) {
      state.productCatalogChanges = data;
    }
  },
};

const actions = {
  DOWNLOAD_FILE: async ({ commit }, payload) => {
    let data = await api.Astor.downloadFile(payload);
    return data;
  },
  GET_FILES_LOGS: async ({ commit }, payload) => {
    const data = await api.Astor.getFilesLogs(payload.page);
    commit("SET_FILES", data);
  },
  GET_SKU_PRICE_CHANGES: async ({ commit }, payload) => {
    const data = await api.Astor.getSkuPricesChanges(payload.guid);
    commit("SET_SKU_PRICES_CHANGES", data);
  },
  GET_PRODUCT_CATALOG_CHANGES: async ({ commit }, payload) => {
    const data = await api.Astor.getProductCatalogChanges(payload.guid);
    commit("SET_PRODUCT_CATALOG_CHANGES", data);
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
