import request from "../core/request";

export default {
  labelAll() {
    return request.execute("get", "/admin/label/list");
  },
  deleteLabel(id) {
    return request.execute("delete", `admin/label/delete/${id}`);
  },
  addLabel(input) {
    return request.execute("post", "admin/label/save", input);
  },
  editLabel(input) {
    return request.execute("put", "admin/label/update", input);
  },
  getLabel(id) {
    return request.execute("get", `admin/label/single/${id}`);
  },
};
