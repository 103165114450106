import api from "@/api";
const name = "ROLEPOWER";
const namespaced = true;
const state = {
  RolePowers: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutatuins = {
  SET_ROLE_POWERS: (state, data) => {
    if (data.type === "success") {
      state.RolePowers = data.data;
    }
  },
};
const actions = {
  GET_ROLE_POWERS: async ({ commit }, payload) => {
    let data = await api.RolePower.getRolePowers(payload);
    // commit("SET_ROLE_POWER", data);
    return data;
  },
  EDIT_ROLE_POWERS: async ({ commit }, payload) => {
    let data = await api.RolePower.editRolePowers(payload);
    // commit("SET_ROLE_POWER", data);
    return data;
  },
  DELETE_ROLE_POWERS: async ({ commit }, payload) => {
    let data = await api.RolePower.deleteRolePowers(payload);
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutatuins,
  actions,
};
