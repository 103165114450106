import request from "../core/request";

export default {
  powerAll() {
    return request.execute("get", "admin/power/all");
  },
  getPower(id) {
    return request.execute("get", `admin/power/${id}`);
  },
  deletePower(id) {
    return request.execute("delete", `admin/power/delete?powerId=${id}`);
  },
  addPower(input) {
    return request.execute("post", "admin/power/add", input);
  },
  editPower(input) {
    return request.execute("put", "admin/power/update", input);
  },
};
