import request from "../core/request";

export default {
  roleAll() {
    return request.execute("get", "admin/roles/all");
  },
  getRole(id) {
    return request.execute("get", "admin/roles/get" + `?roleId=${id}`);
  },
  deleteRole(id) {
    return request.execute("delete", `admin/roles/delete?roleId=${id}`);
  },
  addRole(input) {
    return request.execute(
      "post",
      `admin/roles/add?roleName=${input.name}`,
      input.powerIds
    );
  },
  editRole(input) {
    return request.execute("put", "admin/roles/edit", input);
  },
};
