/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
const name = "COUPONS";
const namespaced = true;
const state = {
  coupons: [],
};
const getters = {
  STATE: (state) => {
    return state.coupons;
  },
};
const mutations = {
  SET_COUPONS: (state, data) => {
    if (data.type !== "error") {
      state.coupons = data;
    }
  },
};
const actions = {
  GET_ALL_COUPONS: async ({ commit }, payload) => {
    let data = await api.Coupons.getAll(payload);
    commit("SET_COUPONS", data);
  },
  ADD_COUPON: async ({ commit }, payload) => {
    let data = await api.Coupons.addCoupon(payload);
    return data;
  },
  EDIT_COUPON: async ({ commit }, payload) => {
    let data = await api.Coupons.editCoupon(payload);
    return data;
  },
  DELETE_COUPON: async ({ commit }, payload) => {
    let data = await api.Coupons.deleteCoupon(payload);
    return data;
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
