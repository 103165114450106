/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
const name = "Reports";
const namespaced = true;
const state = {
  allReports: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  //Получение всего
  SET_REPORTS_LIST: (state, data) => {
    if (data.type === "success") {
      state.allReports = data.data;
    }
  },
};
const actions = {
  GET_ALL_REPORTS: async ({ commit }) => {
    var data = await api.Reports.getAllReport();
    commit("SET_REPORTS_LIST", data);
  },
  DELETE_REPORT: async ({ commit }, payload) => {
    return await api.Reports.deleteReport(payload);
  },
  UPDATE_ALL_REPORTS_ID: async ({ commit }) => {
    return await api.Reports.updateAllReportId();
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
