const dark = {
  dark: false,
  set(data) {
    this.dark = data;
    localStorage.setItem("theme", data);
  },
  get() {
    return this.dark;
  },
};
export default {
  install(Vue) {
    Vue.prototype.$dark = dark;
  },
};
