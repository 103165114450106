/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
import Vue from "vue";
const name = "USER";
const namespaced = true;
const state = {
  staticPages: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_STATIC: (state, data) => {
    if (data.type === "success") {
      state.staticPages = data.data;
    }
  },
};
const actions = {
  GET_STATIC: async ({ commit }, payload) => {
    let data = await api.Static.getStatic(payload);
    commit("SET_STATIC", data);
  },
  ADD_STATIC: async ({ commit }, payload) => {
    let data = await api.Static.addStatic(payload);
    return data;
  },
  SAVE_STATIC: async ({ commit }, payload) => {
    let data = await api.Static.editStatic(payload);
    return data;
  },
  GET_STATIC_ID: async ({ commit }, payload) => {
    let data = await api.Static.getStaticId(payload);
    return data;
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
