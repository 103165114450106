/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
import Vue from "vue";
const name = "STATUS";
const namespaced = true;
const state = {
  allStatus: [],
  payStatus: [],
  cancelTags: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_STATUS_ALL: (state, data) => {
    if (data.type === "success") {
      data.data.forEach((x) => {
        switch (x.title) {
          case "Новый":
            x.color = "rgb(208, 202, 2)";
            break;
          case "В обработке":
            x.color = "rgb(226, 172, 84)";
            break;
          case "Готов к согласованию":
            x.color = "rgb(133, 197, 224)";
            break;
          case "Согласован":
            x.color = "rgb(223, 137, 86)";
            break;
          case "На кассе":
            x.color = "rgb(104, 106, 105)";
            break;
          case "Отгружен":
            x.color = "rgb(58, 159, 201)";
            break;
          case "Доставляется":
            x.color = "rgb(251, 255, 0)";
            break;
          case "Доставлен":
            x.color = "rgb(36, 150, 65)";
            break;
          case "Отменен":
            x.color = "rgb(146, 149, 148)";
            break;
          case "Возврат":
            x.color = "rgb(146, 149, 148)";
            break;
          case "Ожидает назначения на филиал":
            x.color = "rgb(146, 149, 148)";
            break;
          default:
            x.color = "rgb(146, 149, 148)";
            break;
        }
      });
      state.allStatus = data.data;
    }
  },
  SET_PAY_STATUS_ALL: (state, data) => {
    state.payStatus = data;
  },
  SET_CANCEL_TAGS: (state, data) => {
    state.cancelTags = data;
  },
};
const actions = {
  GET_ALL_STATUS: async ({ commit }, payload) => {
    let data = await api.Status.statusAll();
    commit("SET_STATUS_ALL", data);
  },
  GET_ALL_PAY_STATUS: async ({ commit }, payload) => {
    let data = await api.Status.getPayStatusAll();
    commit("SET_PAY_STATUS_ALL", data);
  },
  ADD_STATUS: async ({ commit }, payload) => {
    let data = await api.Status.addStatus(payload);
    return data;
  },
  GET_STATUS: async ({ commit }, payload) => {
    let data = await api.Status.getStatus(payload);
    if (data.type == "success") {
      return data.data;
    }
  },
  // EDIT_STATUS: async ({ commit }, payload) => {
  //   let data = await api.Status.editStatus(payload);
  //   return data;
  // },
  DELETE_STATUS: async ({ commit }, payload) => {
    let data = await api.Status.deleteStatus(payload);
    return data;
  },
  GET_CANCEL_TAGS: async ({ commit }) => {
    const data = await api.Status.getAllCancelTags();
    commit("SET_CANCEL_TAGS", data);
    return data;
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
