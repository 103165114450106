/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
const name = "PushNotify";
const namespaced = true;

const state = {
  allNotify: [],
};

const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  //Получение всего
  SET_NOTIFY: (state, data) => {
    state.allNotify = data;
  },
};
const actions = {
  GET_ALL_NOTIFY: async ({ commit }) => {
    let data = await api.PushNotifications.getAllNotify();
    if (data?.type === "error") return;
    commit("SET_NOTIFY", data);
  },
  DELETE_NOTIFY: async ({ commit }, payload) => {
    return await api.PushNotifications.deleteNotify(payload);
  },
  UPDATE_NOTIFY: async ({ commit }, payload) => {
    return await api.PushNotifications.editNotify(payload);
  },
  ADD_NOTIFY: async ({ commit }, payload) => {
    return await api.PushNotifications.addNotify(payload);
  },
  GET_NOTIFY_REPORT: async ({ commit }, payload) => {
    return await api.PushNotifications.getNotifyExelDayName(payload);
  },
  GET_NOTIFY_REPORT_CLIENTS: async ({ commit }, payload) => {
    return await api.PushNotifications.getNotifyExelClientsName(payload);
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
