import request from "../core/request";

export default {
  getStatic() {
    return request.execute("get", "static/page/get/all");
  },
  addStatic(input) {
    return request.execute("post", "static/page/save", input);
  },
  editStatic(input) {
    return request.execute("put", `static/page/update/${input.id}`, input);
  },
  getStaticId(id) {
    return request.execute("get", `static/page/get/${id}`);
  },
};
