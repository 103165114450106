function differentEnding(number, variant1, variant2, variant3) {
  let str = number.toString();
  let word = "";
  switch (str[str.length - 1]) {
    case "1":
      word = variant1;
      break;
    case "2":
    case "3":
    case "4":
      word = variant2;
      break;
    default:
      word = variant3;
  }
  return word;
}

export default {
  install(Vue) {
    Vue.prototype.$difEnd = differentEnding;
  },
};
