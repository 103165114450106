import request from "../core/request";

export default {
  cityAll() {
    return request.execute("get", "/admin/city/all");
  },
  deleteCity(id) {
    return request.execute("delete", `admin/city/delete/${id}`);
  },
  addCity(input) {
    return request.execute("post", "admin/city/add", input);
  },
  editCity(input) {
    return request.execute("put", "admin/city/update", input);
  },
  getCity(id) {
    return request.execute("get", `admin/city/${id}`);
  },
};
