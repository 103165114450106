/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
import Vue from "vue";
const name = "MEASURE";
const namespaced = true;
const state = {
  allMeasure: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_MEASURE_ALL: (state, data) => {
    if (data.type === "success") {
      state.allMeasure = data.data;
    }
  },
};
const actions = {
  GET_ALL_MEASURE: async ({ commit }) => {
    let data = await api.Measure.measureAll();
    commit("SET_MEASURE_ALL", data);
  },
  ADD_MEASURE: async ({ commit }, payload) => {
    let data = await api.Measure.addMeasure(payload);
    return data;
  },
  GET_MEASURE: async ({ commit }, payload) => {
    let data = await api.Measure.getMeasure(payload);
    if (data.type == "success") {
      return data.data;
    }
  },
  EDIT_MEASURE: async ({ commit }, payload) => {
    let data = await api.Measure.editMeasure(payload);
    return data;
  },
  DELETE_MEASURE: async ({ commit }, payload) => {
    let data = await api.Measure.deleteMeasure(payload);
    return data;
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
