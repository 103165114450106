import request from "../core/request";

export default {
  getAllPenaltyTypes() {
    return request.execute("get", `admin/penalty/types/all`);
  },
  editPenalty(body) {
    return request.execute("put", `admin/penalty/edit/penalty/type`, body);
  },
  downloadPenalty(penaltyId) {
    return request.executeFile(
      "get",
      `admin/penalty/penalty/${penaltyId}/report`
    );
  },
};
