import request from "../core/request";

export default {
  getDelivery(departmentId) {
    return request.execute("get", `delivery/get/${departmentId}`);
  },
  deleteDelivery(deliveryId) {
    return request.execute("delete", `delivery/delete/${deliveryId}`);
  },
  addDelivery(data) {
    return request.execute("post", "delivery/add", data);
  },
  getHotline(departmentId) {
    return request.execute("get", `hotline/get/${departmentId}`);
  },
  deleteHotline(hotlineId) {
    return request.execute("delete", `hotline/delete/${hotlineId}`);
  },
  addHotline(data) {
    return request.execute("post", "hotline/add", data);
  },
};
