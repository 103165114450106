import request from "../core/request";

export default {
  //EXPORT
  getOneParameterByName(name) {
    return request.execute("get", "properties/get/byName/" + name);
  },
  editOneParameter(data) {
    return request.execute("put", "properties/edit", data);
  },
};
