<template>
  <v-app style="background: #404040">
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>
<script>
export default {};
</script>
