/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
const name = "Reviews";
const namespaced = true;

const state = {
  allReviews: [],
  allTags: [],
  deletedTags: [],
  cancelOrderTags: [],
  moderateTags: [],
  allApproved: [],
  allNotapproved: [],
};

const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  //Получение всего
  SET_All_REVIEWS: (state, data) => {
    if (data.type === "success") {
      state.allReviews = data.content;
    }
  },
  SET_All_TAGS: (state, data) => {
    state.allTags = data;
  },
  SET_All_APPROVED: (state, data) => {
    state.allApproved = data;
  },
  SET_All_NOTAPPROVED: (state, data) => {
    state.allNotapproved = data;
  },
  SET_All_DELETED_TAGS: (state, data) => {
    state.deletedTags = data;
  },
  SET_CANCEL_ORDER_TAGS: (state, data) => {
    state.cancelOrderTags = data;
  },
  SET_MODERATE_TAGS: (state, data) => {
    state.moderateTags = data;
  },
};

const actions = {
  GET_ALL_REVIEWS: async ({ commit }, payload) => {
    const data = await api.Reviews.getAllReviews(payload);
    commit("SET_All_REVIEWS", data);
  },
  GET_ALL_TAGS: async ({ commit }) => {
    const data = await api.Reviews.getAllTags();
    commit("SET_All_TAGS", data);
  },
  GET_ALL_APPROVED: async ({ commit }, payload) => {
    const data = await api.Reviews.getAprovedReview(payload);
    commit("SET_All_APPROVED", data);
  },
  GET_ALL_NOTAPPROVED: async ({ commit }, payload) => {
    const data = await api.Reviews.getNotAprovedReview(payload);
    commit("SET_All_NOTAPPROVED", data);
  },
  GET_ALL_DELETED_TAGS: async ({ commit }) => {
    const data = await api.Reviews.getAllDeletedTags();
    commit("SET_All_DELETED_TAGS", data);
  },
  DELETE_ONE_TAG: async ({ commit }, payload) => {
    const data = await api.Reviews.deleteAllTagById([payload]);
    return data;
  },
  RESTORE_ONE_TAG: async ({ commit }, payload) => {
    const data = await api.Reviews.restoreAllTagsById([payload]);
    return data;
  },
  ADD_NEW_TAG: async ({ commit }, payload) => {
    const data = await api.Reviews.addNewTag(payload);
    return data;
  },
  REDACT_TAG: async ({ commit }, payload) => {
    const data = await api.Reviews.redactTag(payload);
    return data;
  },
  // Теги при удалении заказа
  GET_CANCEL_ORDER_TAGS: async ({ commit }) => {
    const data = await api.Reviews.getCancelOrderTags();
    commit("SET_CANCEL_ORDER_TAGS", data);
  },
  EDIT_CANCEL_ORDER_TAG: async ({ commit }, payload) => {
    const data = await api.Reviews.editCancelOrderTag(payload);
    return data;
  },
  ADD_CANCEL_ORDER_TAG: async ({ commit }, payload) => {
    const data = await api.Reviews.addCancelOrderTag(payload);
    return data;
  },
  DELETE_CANCEL_ORDER_TAG: async ({ commit }, payload) => {
    const data = await api.Reviews.deleteCancelOrderTag(payload);
    return data;
  },
  // Теги для модерации продуктов
  GET_MODERATE_TAGS: async ({ commit }) => {
    const data = await api.Reviews.getModerateTags();
    commit("SET_MODERATE_TAGS", data);
  },
  EDIT_MODERATE_TAG: async ({ commit }, payload) => {
    const data = await api.Reviews.editModerateTag(payload);
    return data;
  },
  ADD_MODERATE_TAG: async ({ commit }, payload) => {
    const data = await api.Reviews.addModerateTag(payload);
    return data;
  },
  DELETE_MODERATE_TAG: async ({ commit }, payload) => {
    const data = await api.Reviews.deleteModerateTag(payload);
    return data;
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
