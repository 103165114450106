import request from "../core/request";

export default {
  getAllReport() {
    return request.execute("get", "site/wrong/product/all");
  },
  //Удаление
  deleteReport(id) {
    return request.execute("delete", `site/wrong/product/${id}`);
  },
  // Обновление id у продуктов в таблице ошибок
  updateAllReportId() {
    return request.execute("get", "site/wrong/product/update/productId");
  },
};
