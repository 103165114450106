import axios from "axios";
import Vue from "vue";
import router from "@/router";
import store from "@/store";

const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL, // eslint-disable-line no-undef
  json: true,
});
// перехватчит отправки запросов
client.interceptors.request.use(async function (config) {
  return new Promise(async (resolve, reject) => {
    try {
      let tokenData = JSON.parse(Vue.prototype.$cookie.get("refreshToken"));

      if (tokenData) {
        // Получаем текущее время и время устаревания токена
        let expTime = new Date(tokenData.tokenExpirationDate).getTime();
        let expTimeOfRefresh = new Date(
          tokenData.refreshTokenExpirationDate
        ).getTime();
        let curTime = new Date().getTime();
        // если рефреш токен тоже истек перенаправляем на страницу авторизации
        if (expTimeOfRefresh - curTime <= 50000) {
          Vue.prototype.$cookie.delete("token");
          Vue.prototype.$cookie.delete("refreshToken");
          if (window.location.pathname !== "/login") {
            router.push("/login");
          }
          return;
        }
        //  Сравниваем, если разница более 50 секунд - запускаем процедуру получения нового токена из стора
        if (expTime - curTime <= 50000) {
          Vue.prototype.$cookie.delete("token");
          Vue.prototype.$cookie.delete("refreshToken");
          await store.dispatch("Auth/REFRESH_TOKEN", {
            refreshToken: tokenData.refreshToken,
          });
        }
      } else {
        Vue.prototype.$cookie.delete("token");
        if (window.location.pathname !== "/login") {
          router.push("/login");
        }
      }
      resolve(config);
    } catch (err) {
      console.log("axiosInstance.interceptors.request.use", err);
      resolve(config);
    }
  });
});

export default {
  async execute(method, resource, data = {}, headers = {}) {
    return client({
      crossdomain: true,
      headers: {
        Accept: "*/*",
        TOKEN: Vue.prototype.$cookie.get("token"),
        ...headers,
      },
      method,
      url: resource,
      data,
    }).then(
      (response) => {
        return response.data;
      },
      (error) => {
        // если ошибка авторизации, сбрасываем токен и перенаправляем на авторизацию
        if (
          error.request.status === 403 &&
          window.location.pathname !== "/login"
        ) {
          Vue.prototype.$cookie.delete("token");
          Vue.prototype.$cookie.delete("refreshToken");
          router.push("/login");
        }
        console.log(
          "Ошибка в запросе по пути " +
            resource +
            " | status: " +
            error.request.status
        );
        try {
          return JSON.parse(error.request.response);
        } catch (e) {
          return error.request.response;
        }
      }
    );
  },
  async executeFile(method, resource, data, headers = {}) {
    return client({
      crossdomain: true,
      headers: {
        Accept: "*/*",
        TOKEN: Vue.prototype.$cookie.get("token"),
        ...headers,
      },
      method,
      url: resource,
      data,
      responseType: "blob",
    }).then(
      (response) => {
        return response;
      },
      (error) => {
        console.log(
          "Ошибка в запросе по пути " +
            resource +
            " | status: " +
            error.request.status
        );
        try {
          return JSON.parse(error.request);
        } catch (e) {
          return error.request;
        }
      }
    );
  },
};
