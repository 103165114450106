import request from "../core/request";

export default {
  addressAll() {
    return request.execute("get", "admin/address/type/all");
  },
  deleteAddress(id) {
    return request.execute("delete", `admin/address/type/delete/${id}`);
  },
  addAddress(input) {
    return request.execute("post", "admin/address/type/add", input);
  },
  editAddress(input) {
    return request.execute("put", "admin/address/type/update", input);
  },
  getAddress(id) {
    return request.execute("get", `admin/address/type/${id}`);
  },
};
