/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
const name = "Zone";
const namespaced = true;
const state = {
  departmentZones: [],
};
const getters = {
  DEP_ZONES: (state) => {
    return state.departmentZones;
  },
};
const mutations = {
  SET_DEP_ZONES: (state, data) => {
    state.departmentZones = data;
  },
};
const actions = {
  GET_ALL_ZONE_LIST: async ({ commit }, payload) => {
    let data = await api.Zone.getAllZoneList(payload);
    if (data.type == "success") return data.data;
    return [];
  },
  ADD_NEW_ZONE: async ({ commit }, payload) => {
    return await api.Zone.addNewZone(payload);
  },
  REMOVE_ZONE: async ({ commit }, payload) => {
    return await api.Zone.removeZone(payload);
  },
  UPDATE_ZONE: async ({ commit }, payload) => {
    return await api.Zone.updateZone(payload);
  },
  GET_SINGLE_ZONE: async ({ commit }, payload) => {
    let data = await api.Zone.getSingleZone(payload);
    if (data.type == "success") return data.data;
    return [];
  },
  DEPARTMENT_ZONE_SAVE: async ({ commit }, payload) => {
    let data = await api.Zone.DepartmentZoneSave(payload);
    if (data.type == "success") return data.data;
    return [];
  },
  // Все зоны филиалов
  GET_ALL_DEP_ZONE: async ({ commit }) => {
    let data = await api.Zone.getAllDepZone();
    if (data.type == "success") {
      commit("SET_DEP_ZONES", data.data);
    }
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
