import request from "../core/request";

export default {
  shiftList(data) {
    return request.execute("post", "admin/shift/list/v2", data);
  },
  shiftAdd(input) {
    return request.execute("post", "admin/shift/add", input);
  },
  scheduleList(id) {
    return request.execute(
      "get",
      `admin/employee_schedule/department/list/${id}`
    );
  },
  shiftUpdate(input) {
    return request.execute("put", "admin/shift/update", input);
  },
  shiftDelete(id) {
    return request.execute("delete", `admin/shift/delete/${id}`);
  },
  addSlot(input) {
    return request.execute("post", "admin/shift/shiftSlots/add", input);
  },
  editSlot(input) {
    return request.execute("put", "admin/shift/shiftSlots/edit", input);
  },
  changeShiftDepartment(input) {
    return request.execute(
      "get",
      `admin/shift/changeShiftDepartment/${input.departmentId}/${input.shiftId}`
    );
  },
  getExelShedule(input) {
    return request.execute("post", "admin/report/generate/schedule", input);
  },
};
