/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
import Vue from "vue";
const name = "FLEXPARAMETER";
const namespaced = true;
const state = {
  id: null,
  propertyName: "",
  propertyValue: "",
  description: "",
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_PROPERTY: (state, data) => {
    if (data.type === "success") {
      if (data.data.length === 1) {
        data.data = data.data[0];
      }
      state.id = data.data.id;
      state.propertyValue = data.data.propertyValue;
      state.description = data.data.description;
      state.propertyName = data.data.propertyName;
    }
  },
};
const actions = {
  GET_PROPERTY: async ({ commit }, propertyName) => {
    let data = await api.FlexParameter.getOneParameterByName(propertyName);
    commit("SET_PROPERTY", data);
  },
  EDIT_PROPERTY: async ({ commit, state }, propertyValue) => {
    let editData = {
      id: state.id,
      propertyName: state.propertyName,
      propertyValue,
      description: state.description,
    };
    let data = await api.FlexParameter.editOneParameter(editData);
    commit("SET_PROPERTY", data);
    if (data.type === "success") return true;
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
