/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
import Vue from "vue";
const name = "CLIENTS";
const namespaced = true;
const state = {
  allClient: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_CLIENTS_LIST: (state, data) => {
    if (data.type === "success") {
      state.allClient = data.data;
    }
  },
};
const actions = {
  GET_ALL_CLIENTS: async ({ commit }, payload) => {
    let data = await api.Clients.allClients(payload);
    commit("SET_CLIENTS_LIST", data);
  },
  ADD_CLIENT: async ({ commit }, payload) => {
    let data = await api.Clients.addClient(payload);
    return data;
  },
  GET_CLIENT: async ({ commit }, payload) => {
    let data = await api.Clients.getClient(payload);
    if (data.type == "success") {
      return data.data;
    }
  },
  EDIT_CLIENT: async ({ commit }, payload) => {
    let data = await api.Clients.editClient(payload);
    return data;
  },
  DELETE_CLIENT: async ({ commit }, payload) => {
    let data = await api.Clients.deleteClient(payload);
    return data;
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
