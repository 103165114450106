import Vue from "vue";

export default function showMessage(message, success) {
  if (success) {
    Vue.notify({
      group: "app",
      type: "success",
      title: "Успешно",
      text: message,
    });
    return;
  }
  if (message || message === null) {
    Vue.notify({
      group: "app",
      type: "error",
      title: "Ошибка",
      text: message || "Неизвестная ошибка",
    });
  } else {
    Vue.notify({
      group: "app",
      type: "success",
      title: "Успешно",
      text: "Данные отправлены на сервер",
    });
  }
}
