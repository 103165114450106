import request from "../core/request";

export default {
  downloadFile(input) {
    return request.execute("post", "loader/upload/excel", input.file, {
      Username: input.username,
      Password: input.password,
    });
  },
  getFilesLogs(pageNumber) {
    return request.execute("get", `logAstore/page/${pageNumber}`);
  },
  getSkuPricesChanges(guid) {
    return request.execute("post", "skupricechanges/findByGuid", { guid });
  },
  getProductCatalogChanges(guid) {
    return request.execute("post", "productCatalogChanges/findByGuid", {
      guid,
    });
  },
};
