/* eslint-disable no-undef, no-unused-vars */
import Vue from "vue";
import api from "@/api";
const name = "AUTH";
const namespaced = true;
const state = {};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {};
const actions = {
  SIGN_IN: async ({ commit }, payload) => {
    let data = await api.Auth.signIn(payload);
    return data;
  },
  REFRESH_TOKEN: async ({ commit }, payload) => {
    let data = await api.Auth.refreshToken(payload);
    if (data.type !== "error") {
      Vue.prototype.$cookie.set("token", "Bearer" + data.token);
      Vue.prototype.$cookie.set("refreshToken", JSON.stringify(data));
    }
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
