import api from "@/api";
const name = "TimeControl";
const namespaced = true;
const state = {
  departmentWorkTime: [],
};
const getters = {
  WORK_TIME: (state) => {
    return state.departmentWorkTime;
  },
};
const mutations = {
  SET_DEPARTMENTWORKTIME: (state, data) => {
    state.departmentWorkTime = data;
  },
};
const actions = {
  GET_WORKTIME: async ({ commit }, departmentId) => {
    let data = await api.TimeControl.getDepartmentWorkTime(departmentId);
    const sortedArr = [];
    if (data.length) {
      data.forEach((day) => {
        switch (day.day) {
          case "MONDAY":
            sortedArr[0] = day;
            break;
          case "TUESDAY":
            sortedArr[1] = day;
            break;
          case "WEDNESDAY":
            sortedArr[2] = day;
            break;
          case "THURSDAY":
            sortedArr[3] = day;
            break;
          case "FRIDAY":
            sortedArr[4] = day;
            break;
          case "SATURDAY":
            sortedArr[5] = day;
            break;
          case "SUNDAY":
            sortedArr[6] = day;
            break;
        }
      });
    }
    commit("SET_DEPARTMENTWORKTIME", sortedArr);
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
