import api from "@/api";
const name = "CHAT";
const namespaced = true;
const state = {
  allTags: [],
};
const getters = {
  STATE: (state) => {
    return state.allTags;
  },
};

const mutations = {
  //Получение всего
  SET_All_TAGS: (state, data) => {
    if (data.type !== "error") {
      state.allTags = data;
    }
  },
};

const actions = {
  GET_CHATS: async ({ _ }, payload) => {
    let data = await api.Chat.getChat(payload);
    return data;
  },
  GET_MESSAGES: async ({ _ }, payload) => {
    let data = await api.Chat.getMessages(payload);
    return data;
  },
  OPEN_NEW_CHAT: async ({ _ }, payload) => {
    let data = await api.Chat.openNewChat(payload);
    return data;
  },
  GET_TAGS: async ({ commit }, payload) => {
    let data = await api.Chat.getTags(payload);
    commit("SET_All_TAGS", data);
  },
};
export default {
  name,
  namespaced,
  actions,
  state,
  getters,
  mutations,
};
