import request from "../core/request";

export default {
  measureAll() {
    return request.execute("get", "/admin/measure/all");
  },
  deleteMeasure(id) {
    return request.execute("delete", `admin/measure/delete/${id}`);
  },
  addMeasure(input) {
    return request.execute("post", "admin/measure/add", input);
  },
  editMeasure(input) {
    return request.execute("put", "admin/measure/update", input);
  },
  getMeasure(id) {
    return request.execute("get", `admin/measure/${id}`);
  },
};
