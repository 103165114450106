/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";

const name = "Currency";
const namespaced = true;

const state = {};

const getters = {
  STATE: (state) => {
    return state;
  },
};

const mutations = {};

const actions = {
  FILE_EDIT: async (_, payload) => {
    let data = await api.File.fileEdit(payload);
    return data;
  },
  EXPORT_ORDER_FILE: async (_, payload) => {
    let data = await api.File.exportOrder(payload);
    return data;
  },
  REPORT_POST: async (_, payload) => {
    let data = await api.File.reportPost(payload);
    if (data.type == "success") return data.data;
    return [];
  },
  REPORT_FILE: async (_, payload) => {
    let data = await api.File.reportStatus(payload);
    // проверка на error, а не на succes делается потому, что решили
    // что выполневшиеся запросы не будут иметь поля type и data
    if (data?.type === "error") return [];
    return data;
  },
  REPORT_DEP: async (_, payload) => {
    let data = await api.File.reportDep(payload);
    return data;
  },
  REPORT_REWARD_V2: async (_, payload) => {
    let data = await api.File.reportReward_v2(payload);
    return data;
  },
  REPORT_CLIENTS: async (_, payload) => {
    let data = await api.File.getClientsReport(payload);
    if (data?.type === "error") return [];
    return data;
  },
  REPORT_DISTANCE: async (_, payload) => {
    let data = await api.File.getDistanceReport(payload);
    if (data?.type === "error") return [];
    return data;
  },
  REPORT_RATINGS: async () => {
    let data = await api.File.getRatingsReport();
    return data;
  },
  REPORT_PROVIDERS: async (_, payload) => {
    let data = await api.File.getProvidersReport(payload);
    return data;
  },
  REPORT_SALES_OPEN: async (_, payload) => {
    let data;
    if (payload.onlineType) {
      data = await api.File.reportOnlineSalesOpen(payload);
    } else {
      data = await api.File.reportSalesOpen(payload);
    }
    return data;
  },
  REPORT_SALES_CLOSE: async (_, payload) => {
    let data;
    if (payload.onlineType) {
      data = await api.File.reportOnlineSalesClose(payload);
    } else {
      data = await api.File.reportSalesClose(payload);
    }
    return data;
  },
  REPORT_PLATFORM: async (_, payload) => {
    let data;
    if (payload.onlineType) {
      data = await api.File.reportOnlinePlatform(payload.body);
    } else {
      data = await api.File.reportPlatform(payload.body);
    }
    return data;
  },
  REPORT_COMPENSATION: async (_, payload) => {
    let data = await api.File.reportEmployeeCompensation(payload);
    return data;
  },

  REPORT_AIRBA: async (_, payload) => {
    let data = await api.File.reportAirba(payload);
    return data;
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
