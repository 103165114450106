import api from "@/api";

const name = "EDUCATION";
const namespaced = true;
const state = {
  videos: [],
};
const getters = {
  STATE: (state) => state.videos,
};
const mutations = {
  SET_VIDEOS: (state, data) => {
    state.videos = data;
  },
};
const actions = {
  GET_ALL_VIDEO: async ({ commit }) => {
    let data = await api.Education.getAllVideo();
    commit("SET_VIDEOS", data);
  },
  ADD_CURRENCY: async ({ commit }, payload) => {
    let data = await api.Currency.addCurrency(payload);
    return data;
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
