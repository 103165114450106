/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
const name = "PROVIDER_GROUPS";
const namespaced = true;
const state = {
  groups: [],
};
const getters = {
  STATE: (state) => {
    return state.groups;
  },
};
const mutations = {
  SET_GROUPS: (state, data) => {
    if (data.type !== "error") {
      state.groups = data;
    }
  },
};
const actions = {
  GET_ALL_GROUPS: async ({ commit }, payload) => {
    let data = await api.ProviderGroups.getAll(payload);
    commit("SET_GROUPS", data);
  },
  ADD_GROUP: async ({ commit }, payload) => {
    let data = await api.ProviderGroups.addGroup(payload);
    return data;
  },
  EDIT_GROUP: async ({ commit }, payload) => {
    let data = await api.ProviderGroups.editGroup(payload);
    return data;
  },
  DELETE_GROUP: async ({ commit }, payload) => {
    let data = await api.ProviderGroups.deleteGroup(payload);
    return data;
  },
  ADD_GROUP_TO_PROVIDER: async ({ commit }, payload) => {
    let data = await api.ProviderGroups.addGroupToProvider(payload);
    return data;
  },
  REMOVE_GROUP_FROM_PROVIDER: async ({ commit }, payload) => {
    let data = await api.ProviderGroups.removeGroupFromProvider(payload);
    return data;
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
