import request from "../core/request";

export default {
  getShelf(input) {
    return request.execute("post", "stack/get/all", input);
  },
  addShelf(input) {
    return request.execute("post", "stack/add/stack", input);
  },
  excelShelf(input) {
    return request.execute("post", "stack/file/export", input);
  },
  removeShelf(id) {
    return request.execute("delete", `stack/remove/${id}`);
  },
  editShelf(input) {
    return request.execute("put", "stack/edit", input);
  },
  exportExcel() {
    return request.execute("post", "admin/stacks");
  },
};
