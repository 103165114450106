import request from "../core/request";

export default {
  getAllZoneList(id) {
    return request.execute("get", `admin/department/zone/all/${id}`);
  },
  getSingleZone(id) {
    return request.execute("get", `admin/zone/${id}`);
  },
  addNewZone(data) {
    return request.execute("post", "admin/zone/add", data);
  },
  DepartmentZoneSave(data) {
    return request.execute("post", "admin/department/zone/save ", data);
  },
  removeZone(id) {
    return request.execute("delete", `admin/zone/delete/${id}`);
  },
  updateZone(data) {
    return request.execute("put", "admin/zone/update", data);
  },
  // новые апи
  getAllDepZone() {
    return request.execute("get", "admin/zone/all");
  },
  bindDepToZone(data) {
    return request.execute("post", "admin/department/zone/save", data);
  },
  getDepZone(departmentId) {
    return request.execute("get", `admin/department/zone/all/${departmentId}`);
  },
  editDepZone(data) {
    return request.execute("put", `admin/department/zone/update`, data);
  },
  deleteDepZone(departmentZoneId) {
    return request.execute(
      "delete",
      `admin/department/zone/delete/${departmentZoneId}`
    );
  },
};
