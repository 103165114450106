import request from "../core/request";

export default {
  getCallbacks() {
    return request.execute("get", "admin/vacancy/get/all/callbacks");
  },
  addCallback(data) {
    return request.execute("post", "admin/vacancy/add/callback/" + data);
  },
  deleteCallback(id) {
    return request.execute("delete", "admin/vacancy/delete/callback/" + id);
  },
  getRequirements() {
    return request.execute("get", "admin/vacancy/get/all/requirements");
  },
  addRequirement(data) {
    return request.execute(
      "post",
      "admin/vacancy/add/requirement?requirementDescription=" + data
    );
  },
  deleteRequirement(id) {
    return request.execute("delete", "admin/vacancy/delete/requirement/" + id);
  },
  getConditions() {
    return request.execute("get", "admin/vacancy/get/all/conditions");
  },
  addCondition(data) {
    return request.execute(
      "post",
      "admin/vacancy/add/condition?conditionDescription=" + data
    );
  },
  deleteCondition(id) {
    return request.execute("delete", "admin/vacancy/delete/condition/" + id);
  },
  getTasks() {
    return request.execute("get", "admin/vacancy/get/all/tasks");
  },
  addTask(data) {
    return request.execute(
      "post",
      "admin/vacancy/add/task?taskDescription=" + data
    );
  },
  deleteTask(id) {
    return request.execute("delete", "admin/vacancy/delete/task/" + id);
  },
  getAllVacancies(data) {
    return request.execute(
      "get",
      `admin/vacancy/get/all/page?page=${data.page}&size=${data.size}`
    );
  },
  editVacancy(data) {
    return request.execute("get", "admin/vacancy/edit", data);
  },
  deleteVacancy(id) {
    return request.execute("delete", "admin/vacancy/delete/vacancy/" + id);
  },
  addVacancy(data) {
    return request.execute("post", "admin/vacancy/add", data);
  },
};
