const power = {
  list: [],
  set(data) {
    this.list = data;
  },
  check(val) {
    if (!this.list) {
      return;
    }
    let powers = this.list.map((x) => {
      return x.keyName;
    });
    return powers.indexOf(val) !== -1;
  },
};
export default {
  install(Vue) {
    Vue.prototype.$power = power;
  },
};
