import request from "../core/request";

export default {
  getAll() {
    return request.execute("get", `admin/categoryOwnership`);
  },
  addGroup(body) {
    return request.execute("post", "admin/categoryOwnership", body);
  },
  editGroup(body) {
    return request.execute("put", "admin/categoryOwnership", body);
  },
  deleteGroup(id) {
    return request.execute("delete", `admin/categoryOwnership/${id}`);
  },
  addGroupToProvider(data) {
    return request.execute(
      "put",
      `admin/categoryOwnership/assign?providerId=${data.providerId}&groupId=${data.groupId}`
    );
  },
  removeGroupFromProvider(id) {
    return request.execute(
      "delete",
      `admin/categoryOwnership/remove?providerId=${id}`
    );
  },
};
