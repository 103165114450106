/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";

const name = "CURRENCY";
const namespaced = true;
const state = {
  cityCurrency: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_CURRENCY_LIST: (state, data) => {
    if (data.type === "success") {
      state.cityCurrency = data.data;
    }
  },
};
const actions = {
  GET_CURRENCY: async ({ commit }, payload) => {
    let data = await api.Currency.getCurrency(payload);
    commit("SET_CURRENCY_LIST", data);
  },
  ADD_CURRENCY: async ({ commit }, payload) => {
    let data = await api.Currency.addCurrency(payload);
    return data;
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
