import request from "../core/request";

export default {
  getAllVideo() {
    return request.execute("get", `/admin/educational/get/video/all`);
  },
  addVideo(body) {
    return request.execute("post", `admin/educational/add/video`, body);
  },
  addTest(name) {
    return request.execute(
      "get",
      `admin/educational/add/educationalTest?name=${name}`
    );
  },
  bindTestToVideo(body) {
    return request.execute("put", `/admin/educational/set/video/test`, body);
  },
  addQuestion(body) {
    return request.execute(
      "post",
      `/admin/educational/add/educationalTestQuestions`,
      body
    );
  },
  deleteVideo(videoId) {
    return request.execute(
      "delete",
      `admin/educational/delete/video/${videoId}`
    );
  },
  deleteQuestion(id) {
    return request.execute(
      "delete",
      `admin/educational/delete/educationalTestQuestions/${id}`
    );
  },
  editQuestion(body) {
    return request.execute(
      "put",
      `/admin/educational/edit/educationalTestQuestions`,
      body
    );
  },
  editVideo(body) {
    return request.execute("put", `/admin/educational/edit/video`, body);
  },
  editTest(body) {
    return request.execute("put", `/admin/educational/edit/test`, body);
  },
};
