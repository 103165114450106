/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
const name = "CITY";
const namespaced = true;
const state = {
  allCity: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
  CITY: (state) => {
    return state.allCity;
  },
};
const mutations = {
  SET_CITY_ALL: (state, data) => {
    if (data.type === "success") {
      let arr = [];
      for (let i = 0; i < data.data.length; i++) {
        state.allCity = data.data[i];
        arr.push(data.data[i]);
      }
      state.allCity = arr;
    }
  },
};
const actions = {
  GET_ALL_CITY: async ({ commit }, payload) => {
    let data = await api.City.cityAll();
    commit("SET_CITY_ALL", data);
  },
  ADD_CITY: async ({ commit }, payload) => {
    let data = await api.City.addCity(payload);
    return data;
  },
  GET_CITY: async ({ commit }, payload) => {
    let data = await api.City.getCity(payload);
    if (data.type == "success") {
      return data.data;
    }
  },
  EDIT_CITY: async ({ commit }, payload) => {
    let data = await api.City.editCity(payload);
    return data;
  },
  DELETE_CITY: async ({ commit }, payload) => {
    let data = await api.City.deleteCity(payload);
    return data;
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
