import request from "../core/request";
export default {
  /**
   * Получить список стеллажей по департаменту
   * @param {Number} departmentId
   * @returns Возвращает список объектов, отсортированных по stackNumber (номеру стеллажа)
   */
  getAllStacksByDepartment(departmentId) {
    return request.execute("get", `stack/get/stack/${departmentId}`);
  },

  /**
   * Найти стеллаж на основе departmentId и productId и providerId
   * @param {Object} body
   * @returns сущность стеллажа
   */
  findStackByDepartmentIdAndProductId(body) {
    return request.execute("put", `stack/get/stack`, body);
  },

  /**
   * Добавить стеллаж
   * @param {Object} input - хранятся данные для сущности стеллажа (stack)
   * @returns сущность стеллажа
   */
  addNewStack(input) {
    return request.execute("post", "stack/add/stack", input);
  },

  /**
   * Изменить стеллаж
   * @param {Number} stackId - id стеллажа
   * @param {Object} input - объект в котором хранятся данные о стеллаже, который изменится
   * @returns Возвращает один объект - изменённое место хранения
   */
  editStackById(stackId, input) {
    return request.execute("put", `stack/edit/stack/${stackId}`, input);
  },

  /**
   * Удалить стеллаж.
   * При удалении также удаляются все полки этого места хранения (StackShelfAyan)
   * и все связи товаров с этими полками (ShelfProductAyan).
   * @param {Number} stackId - id стеллажа
   * @returns void
   */
  deleteStackById(stackId) {
    return request.execute("delete", `stack/delete/stack/${stackId}`);
  },

  /**
   * Получение полки на основе id
   * @param {Number} shelfId - id полки
   * @returns возвращает сущность "Полки" (shelf)
   */
  getShelfById(shelfId) {
    return request.execute("get", `stack/get/shelf/${shelfId}`);
  },

  /**
   * Получение полок на основе id стеллажа
   * @param {Number} stackId - id места хранения (стеллажа)
   * @returns Возвращает список объектов сущностей полок
   */
  getShelvesByStackId(stackId) {
    return request.execute("get", `stack/get/shelf/list/${stackId}`);
  },

  /**
   * Добавление полки
   * @param {Object} input - хранит данные о сущности полки (shelf)
   * @returns Возвращает объект новой созданной полки
   */
  addNewShelf(input) {
    return request.execute("post", "stack/add/shelf", input);
  },

  /**
   * Изменение полки
   * @param {Number} shelfId - id полки
   * @param {Object} input - хранит данные о сущности полки
   * @returns Возвращает объект изменённой полки
   */
  editShelfById(shelfId, input) {
    return request.execute("put", `stack/edit/shelf/${shelfId}`, input);
  },

  /**
   * Удаление полки.
   * При удалении также удаляются все связи товаров с этой полкой (ShelfProductAyan).
   * @param {Number} shelfId - id полки
   * @returns void
   */
  deleteShelfById(shelfId) {
    return request.execute("delete", `stack/delete/shelf/${shelfId}`);
  },

  /**
   * Получение товаров на основе id полки
   * @param {Number} shelfId -Id полки
   * @returns Возвращает список объектов - связанных с этой полкой товаров
   */
  getShelfProductsByShelfId(shelfId) {
    return request.execute("get", `stack/get/shelf-product/list/${shelfId}`);
  },

  /**
   * Удаление нескольких товаров по id
   * @param {Array} shelfProductIdsArray - список значений shelfProductId - id связи товара с полкой, e.g.: [3827, 3828]
   * @returns void
   */
  deleteSeveralShelfProductsById(shelfProductIdsArray) {
    return request.execute(
      "delete",
      "stack/delete/shelf-product/",
      shelfProductIdsArray
    );
  },

  /**
   * Получить список всех типов стеллажей
   * @returns возвращает список всех типов оборудования
   */
  getAllStackTypes() {
    return request.execute("get", "stack/get/all/stack-types");
  },

  /**
   * Добавить тип стеллажа
   * @param {Object} input - сущность "Тип стеллажа"
   * @returns возвращает один объект - новый созданный тип оборудования
   */
  addNewStackType(input) {
    return request.execute("post", "stack/add/stack-type", input);
  },

  /**
   * Изменить тип стеллажа
   * @param {Number} stackTypeId - id типа стеллажа
   * @param {Object} input - хранит данные о сущности стеллажа
   * @returns Возвращает один объект - изменённый тип оборудования
   */
  editStackTypeById(stackTypeId, input) {
    return request.execute(
      "put",
      `stack/edit/stack-type/${stackTypeId}`,
      input
    );
  },

  /**
   * Удалить тип стеллажа.
   * В процессе происходит проверка - при попытке удалить
   * тип оборудования, который связан с местом хранения, возникнет ошибка.
   * @param {Number} stackTypeId
   * @returns void
   */
  deleteStackTypeById(stackTypeId) {
    return request.execute("delete", `stack/delete/stack-type/${stackTypeId}`);
  },

  /**
   * Получить параметры очереди исключений для сортировки товаров для сборщиков
   * @returns Возвращает список объектов (отсортированный по полю position)
   */
  getAllExcludedParams() {
    return request.execute("get", "stack/get/excluded/all");
  },

  /**
   * Добавить категорию в очередь исключений для сортировки товаров для сборщиков.
   * Категория попадает в конец очереди.
   * @param {Number} categoryId - id категории, которую планируется добавить в очередь исключений.
   * @returns Возвращает объект нового добавленного параметра очереди исключений
   */
  addCategoryIntoExcludedQueue(categoryId) {
    return request.execute("post", `stack/add/excluded/${categoryId}`);
  },

  /**
   * Изменить порядок категорий в очереди исключений для сортировки товаров для сборщиков
   * @param {Array} propertyIdsArray - В качестве параметров в теле запроса необходимо передать в виде списка объектов все id параметров с нумерацией порядка
   * @returns void
   */
  editCategoryOrderIntoExcludedQueue(propertyIdsArray) {
    return request.execute("put", "/stack/edit/excluded/", propertyIdsArray);
  },

  /**
   * Удалить параметр очереди исключений для сортировки товаров для сборщиков
   * @param {Number} parameterId - id параметра
   * @returns void
   */
  deleteExcludedParameterById(parameterId) {
    return request.execute("delete", `stack/delete/excluded/${parameterId}`);
  },

  importStackInfoByExcel(file) {
    return request.execute("post", "stack/upload/excel", file);
  },

  excportStackInfoExcelByDepartmentId(departmentId) {
    return request.execute("get", `stack/download/excel/${departmentId}`);
  },

  getExelList() {
    return request.execute("get", `stack/get/excel/list`);
  },
};
