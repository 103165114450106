import request from "../core/request";

export default {
  //   body = {
  // "cityId" : 1,
  // "startTime" : "2023-08-02",
  // "endTime" : "2023-08-03",
  // "page" : 0,
  // "statuses" : [1, 2, 3],
  // "departmentIds" : [5, 7],
  // "couriers" : "568",
  // "onlyPreOrders" : false,
  // "pickers" : [538],
  // "pageSize" : 5
  // }
  orderAll(body) {
    return request.execute("post", "/admin/providerOrders/getOrdersV3", body);
  },
  preOrderAll(input) {
    return request.execute("post", "admin/order/allPreOrders", input);
  },
  deleteOrder(orderId) {
    return request.execute("delete", `site/provider/order/delete/${orderId}`);
  },
  addOrder(input) {
    return request.execute("post", "/site/provider/order/create", input);
  },
  editOrder(input) {
    return request.execute("put", "site/provider/order/update", input);
  },
  getOrder(orderId) {
    return request.execute("get", `admin/providerOrders/single/${orderId}`);
  },
  findOrder(orderId) {
    return request.execute("get", `admin/providerOrders/getOrder/${orderId}`);
  },
  orderHistory(id) {
    return request.execute("get", `admin/providerOrderStatus/history/${id}`);
  },
  orderStatistics(cityId) {
    return request.execute(
      "get",
      `admin/providerOrders/statistic/city/${cityId}`
    );
  },
  orderPaymentHistory(id) {
    return request.execute("get", `admin/paymentStatus/get/history/${id}`);
  },
  orderEmployeeHistory(id) {
    return request.execute("get", `admin/providerOrders/get/logs/${id}`);
  },
  orderAllHistory(orderId) {
    return request.execute(
      "get",
      `admin/providerOrders/getProviderOrderHistory/${orderId}`
    );
  },
  editStatus(input) {
    return request.execute(
      "put",
      `admin/providerOrderStatus/edit/${input.orderId}/${input.statusId}`
    );
  },
  editStatusWithTag(input) {
    return request.execute("put", "admin/providerOrderStatus/edit", input);
  },
  editPaymentStatus(input) {
    return request.execute(
      "put",
      `admin/paymentStatus/edit/${input.orderId}/${input.statusId}`
    );
  },
  editPayment(data) {
    return request.execute("put", "admin/order/payment/type/edit", data);
  },
  editDepartment(data) {
    return request.execute(
      "post",
      "/admin/providerOrders/department/edit",
      data
    );
  },
  editPicker(data) {
    return request.execute("post", "admin/providerOrders/set/picker", data);
  },
  editCourier(data) {
    return request.execute("post", "admin/providerOrders/set/courier", data);
  },
  getStatistic(data) {
    return request.execute("post", "admin/providerOrders/statistic", data);
  },
  editDeliveryTime(data) {
    return request.execute(
      "post",
      `site/provider/order/edit/deliveryTime/${data.id}`,
      {
        time: data.time,
      }
    );
  },
  returnOrder(payload) {
    return request.execute("post", "admin/epay/refund", payload);
  },
  expectedDeliveryTime(orderId) {
    return request.execute("get", `admin/pack/delivery/log/${orderId}`);
  },
};
