import api from "@/api";
const name = "LOGISTIC";
const namespaced = true;
const state = {
  company: [],
  deliveryTypes: [],
};
const getters = {
  COMPANY: (s) => s.company,
  DELIVERY_TYPES: (s) => s.deliveryTypes,
};
const mutations = {
  SET_COMPANY: (state, data) => {
    state.company = data;
  },
  SET_DELIVERY_TYPES: (state, data) => {
    state.deliveryTypes = data;
  },
};
const actions = {
  GET_COMPANY: async ({ commit }) => {
    let data = await api.Logistic.getAllCompany();
    commit("SET_COMPANY", data);
  },
  GET_DELIVERY_TYPES: async ({ commit }) => {
    let data = await api.Logistic.getAllDeliveryTypes();
    commit("SET_DELIVERY_TYPES", data);
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
