import request from "../core/request";

export default {
  getRolePowers(id) {
    return request.execute("get", `admin/role/power/get/${id}`);
  },
  editRolePowers(input) {
    return request.execute(
      "post",
      `admin/role/power/edit?roleId=${input.roleId}`,
      input.powerIds
    );
  },
  deleteRolePowers(input) {
    return request.execute("delete", "admin/role/power/delete/serveral", input);
  },
};
