import request from "../core/request";

export default {
  statusAll() {
    return request.execute("get", "/admin/status/all");
  },
  deleteStatus(id) {
    return request.execute("post", `admin/status/delete/${id}`);
  },
  deleteVirtualStatus(id) {
    return request.execute("post", `admin/status/virtual/delete/${id}`);
  },
  addStatus(input) {
    return request.execute("post", "admin/status/add", input);
  },
  // editStatus(input) {
  //   return request.execute("put", "admin/providerOrderStatus/edit", input);
  // },
  getStatus(id) {
    return request.execute("get", `admin/status/${id}`);
  },
  getPayStatusAll(id) {
    return request.execute("get", `admin/paymentStatus/get/all`);
  },
  getAllCancelTags() {
    return request.execute("get", "admin/cancelOrderTag/all");
  },
};
