import request from "../core/request";

export default {
  getAll() {
    return request.execute("get", `/admin/coupon/all `);
  },
  addCoupon(body) {
    return request.execute("post", "admin/coupon/add", body);
  },
  editCoupon(body) {
    return request.execute("put", "admin/coupon/edit", body);
  },
  deleteCoupon(id) {
    return request.execute("delete", `admin/coupon/delete/${id}`);
  },
};
