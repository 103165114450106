/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
const name = "PAYMENT";
const namespaced = true;
const state = {
  allPayment: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_PAYMENT_TYPE: (state, data) => {
    if (data.type === "success") {
      state.allPayment = data.data;
    }
  },
};
const actions = {
  GET_ALL_PAYMENT: async ({ commit }, payload) => {
    let data = await api.Payment.paymentAll(payload);
    commit("SET_PAYMENT_TYPE", data);
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
