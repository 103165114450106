import request from "../core/request";

export default {
  getAllNotify() {
    return request.execute(
      "get",
      "admin/fcm/notification/get/all/notifications"
    );
  },
  //body{
  //     "isForClientWithoutOrders" : true,
  //     "settingName" : "qwe",
  //     "smsText" : "Zxc",
  //     "pushTitle" : "zxc",
  //     "pushBody" : "zxc",
  //     "daysBefore" : 3,
  //     "isOn" : true
  //   }
  addNotify(body) {
    return request.execute(
      "post",
      `admin/fcm/notification/add/notifications`,
      body
    );
  },
  // Обновление id у продуктов в таблице ошибок
  deleteNotify(id) {
    return request.execute(
      "delete",
      `admin/fcm/notification/delete/notifications/${id}`
    );
  },
  editNotify(body) {
    return request.execute(
      "put",
      `admin/fcm/notification/edit/notifications`,
      body
    );
  },
  //   body {"date" : "2023-05-25"}
  getNotifyExelDayName(body) {
    return request.execute("post", `admin/fcm/notification/get/excel`, body);
  },
  //   body {
  //   "isForClientWithoutOrders" : true,
  //   "daysBefore" : 3
  // }
  getNotifyExelClientsName(body) {
    return request.execute(
      "post",
      `admin/fcm/notification/create/excel/clients`,
      body
    );
  },
};
