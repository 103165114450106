import request from "../core/request";
const REVIEW_PATH = "admin/order/review";

export default {
  getAllReviews(data) {
    return request.execute(
      "get",
      `${REVIEW_PATH}/all/${data.page}/${data.size}`
    );
  },
  getOneReviewById(reviewId) {
    return request.execute("get", `${REVIEW_PATH}/${reviewId}`);
  },
  getAllTags() {
    return request.execute("get", `admin/provider/order/review/tag/all`);
  },
  getAllDeletedTags() {
    return request.execute("get", `admin/provider/order/review/tag/deleted`);
  },
  //Добавление нового тэга, body {title:string, ratings:number[]}
  addNewTag(body) {
    return request.execute("post", `admin/provider/order/review/tag/add`, body);
  },
  //Изменение тэга, body {id:number,title:string, ratings:number[], deleted?:boolean}
  redactTag(body) {
    return request.execute("put", `admin/provider/order/review/tag/edit`, body);
  },
  //удаление нескольких тэгов по id, arrWithTagsId:number[]
  deleteAllTagById(arrWithTagsId) {
    return request.execute(
      "delete",
      `admin/provider/order/review/tag/delete/all`,
      arrWithTagsId
    );
  },
  //восстановление нескольких тэгов по id, arrWithTagsId:number[]
  restoreAllTagsById(arrWithTagsId) {
    return request.execute(
      "put",
      `admin/provider/order/review/tag/restore/all`,
      arrWithTagsId
    );
  },

  // Теги при удалении заказа
  getCancelOrderTags() {
    return request.execute("get", `admin/cancelOrderTag/all`);
  },

  addCancelOrderTag(payload) {
    return request.execute("post", `admin/cancelOrderTag/add/${payload}`);
  },
  // body = {"id": 28,"name": "string"}
  editCancelOrderTag(body) {
    return request.execute("put", `admin/cancelOrderTag/edit`, body);
  },
  deleteCancelOrderTag(tagId) {
    return request.execute("delete", `admin/cancelOrderTag/delete/${tagId}`);
  },

  // Теги для модерации товаров
  getModerateTags() {
    return request.execute(
      "get",
      `admin/moderatorProviderProducts/cancel-tag?deleted=false`
    );
  },

  addModerateTag(body) {
    return request.execute(
      "post",
      `admin/moderatorProviderProducts/cancel-tag/add`,
      body
    );
  },

  editModerateTag(body) {
    return request.execute(
      "put",
      `admin/moderatorProviderProducts/cancel-tag/edit`,
      body
    );
  },
  deleteModerateTag(tagId) {
    return request.execute(
      "delete",
      `admin/moderatorProviderProducts/cancel-tag/delete/${tagId}`
    );
  },

  // отзывы на товар
  getNotAprovedReview(payload) {
    return request.execute(
      "post",
      `admin/provider/review/get/not-approved`,
      payload
    );
  },
  getAprovedReview(payload) {
    return request.execute(
      "post",
      `admin/provider/review/get/approved`,
      payload
    );
  },
  approveReview(providerProductReviewId) {
    return request.execute(
      "put",
      `admin/provider/review/approve/${providerProductReviewId}`
    );
  },
  disapproveReview(providerProductReviewId) {
    return request.execute(
      "put",
      `admin/provider/review/disapprove/${providerProductReviewId}`
    );
  },
  deleteReview(providerProductReviewId) {
    return request.execute(
      "delete",
      `admin/provider/review/delete/${providerProductReviewId}`
    );
  },
};
