/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
const name = "Phone";
const namespaced = true;
const state = {
  deliveryList: [],
  hotlineList: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_DELIVERYLIST: (state, data) => {
    state.deliveryList = data;
  },
  SET_HOTLINELIST: (state, data) => {
    state.hotlineList = data;
  },
  REMOVE_DELIVERY: (state, data) => {
    let deletingIndex = state.deliveryList.findIndex((el) => el.id === data);
    state.deliveryList.splice(deletingIndex, 1);
  },
  REMOVE_HOTLINE: (state, data) => {
    let deletingIndex = state.hotlineList.findIndex((el) => el.id === data);
    state.hotlineList.splice(deletingIndex, 1);
  },
  ADD_DELIVERY: (state, data) => {
    state.deliveryList.push(data);
  },
  ADD_HOTLINE: (state, data) => {
    state.hotlineList.push(data);
  },
};
const actions = {
  GET_DELIVERY: async ({ commit }, departmentId) => {
    let data = await api.Phone.getDelivery(departmentId);
    if (data.type == "success") commit("SET_DELIVERYLIST", data.data);
  },
  GET_HOTLINE: async ({ commit }, departmentId) => {
    let data = await api.Phone.getHotline(departmentId);
    if (data.type == "success") commit("SET_HOTLINELIST", data.data);
  },
  DELETE_DELIVERY: async ({ commit }, deliveryId) => {
    if (deliveryId === 1) {
      return { data: "НЕЛЬЗЯ УДАЛИТЬ СТАНДАРТНЫЙ НОМЕР" };
    } else {
      let data = await api.Phone.deleteDelivery(deliveryId);
      if (data.type == "success") commit("REMOVE_DELIVERY", deliveryId);
      return data;
    }
  },
  DELETE_HOTLINE: async ({ commit }, hotlineId) => {
    if (hotlineId === 1) {
      return { data: "НЕЛЬЗЯ УДАЛИТЬ СТАНДАРТНЫЙ НОМЕР" };
    } else {
      let data = await api.Phone.deleteHotline(hotlineId);
      if (data.type == "success") commit("REMOVE_HOTLINE", hotlineId);
      return data;
    }
  },
  ADD_DELIVERY: async ({ commit }, payload) => {
    let data = await api.Phone.addDelivery(payload);
    if (data.type == "success") commit("ADD_DELIVERY", data.data);
    return data;
  },
  ADD_HOTLINE: async ({ commit }, payload) => {
    let data = await api.Phone.addHotline(payload);
    if (data.type == "success") commit("ADD_HOTLINE", data.data);
    return data;
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
