import request from "../core/request";

export default {
  getChat(payload) {
    return request.execute("post", `chats/search`, payload);
  },
  getMessages(payload) {
    return request.execute(
      "get",
      `chats/${payload.chatId}/messages/${payload.senderId}`
    );
  },
  sendImage(payload) {
    return request.execute(
      "post",
      `chats/image/${payload.chatId}`,
      payload.file
    );
  },
  openNewChat(recipientId) {
    return request.execute("post", `chats/open`, { recipientId });
  },
  getTags() {
    return request.execute("get", `admin/chatTag `);
  },
  addTag(body) {
    return request.execute("post", `admin/chatTag`, body);
  },
  deleteTag(tagId) {
    return request.execute("delete", `admin/chatTag/${tagId}`);
  },
  addQuestion(body) {
    return request.execute("post", `admin/chatTag/question`, body);
  },
  deleteQuestion(id) {
    return request.execute("delete", `admin/chatTag/question/${id}`);
  },
  addAnswer(payload) {
    return request.execute(
      "post",
      `admin/chatTag/answer/${payload.questionId}`,
      { answer: payload.answer }
    );
  },
  deleteAnswer(id) {
    return request.execute("delete", `admin/chatTag/answer/${id}`);
  },
};
