import api from "@/api";
const name = "CATALOG";
const namespaced = true;
const state = {
  catalogCategoryList: [],
};
const getters = {
  STATE: (s) => s,
  CATALOG_CATEGORY_LIST: (s) => s.catalogCategoryList,
};
const mutations = {
  SET_CATALOG_LIST: (state, data) => {
    state.catalogCategoryList = data;
  },
  CHANGE_CATALOG_LIST: (state, data) => {},
};
const actions = {
  GET_ALL_CATEGORIES: async ({ commit }) => {
    let data = await api.Catalog.getCatalogCategoriesList();
    commit("SET_CATALOG_LIST", data);
    return data;
  },
  EDIT_POSITION: async ({ commit }, payload) => {
    let data = await api.Catalog.changeOrder(payload);
    return data;
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
