import request from "../core/request";

export default {
  allClients(id) {
    return request.execute("get", `/client/all/${id.page}/${id.pageSize}`);
  },
  addClient(input) {
    return request.execute("post", "admin/client/add", input);
  },
  getClient(id) {
    return request.execute("get", `admin/client/${id}`);
  },
  editClient(input) {
    return request.execute("put", "admin/client/update", input);
  },
  deleteClient(id) {
    return request.execute("delete", `admin/client/delete/${id}`);
  },
};
