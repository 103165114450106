import request from "../core/request";

export default {
  addressAll(id) {
    return request.execute(
      "get",
      `/admin/address/all/${id.page}/${id.pageSize}`
    );
  },
  deleteAddress(id) {
    return request.execute("delete", `admin/address/delete/${id}`);
  },
  addAddress(input) {
    return request.execute("post", "admin/address/add", input);
  },
  editAddress(input) {
    return request.execute("put", "admin/address/update", input);
  },
  getAddress(id) {
    return request.execute("get", `admin/address/${id}`);
  },
};
