/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";

const name = "USER";
const namespaced = true;
const state = {
  shelfAll: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_SHELF: (state, data) => {
    if (data.type === "success") {
      state.shelfAll = data.data.content;
    }
  },
};
const actions = {
  GET_SHELF: async ({ commit }, payload) => {
    let data = await api.Shelf.getShelf(payload);
    commit("SET_SHELF", data);
  },
  ADD_SHELF: async ({ commit }, payload) => {
    return await api.Shelf.addShelf(payload);
  },
  EXCEL_SHELF: async ({ commit }, payload) => {
    return await api.Shelf.excelShelf(payload);
  },
  REMOVE_SHELF: async ({ commit }, payload) => {
    return await api.Shelf.removeShelf(payload);
  },
  EDIT_SHELF: async ({ commit }, payload) => {
    return await api.Shelf.editShelf(payload);
  },
  EXPORT_SHELF: async ({ commit }, payload) => {
    return await api.Shelf.exportExcel(payload);
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
