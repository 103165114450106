/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
import Vue from "vue";
const name = "POWER";
const namespaced = true;
const state = {
  allPower: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_POWER: (state, data) => {
    if (data.type === "success") {
      state.allPower = data.data;
    }
  },
};
const actions = {
  GET_ALL_POWERS: async ({ commit }, payload) => {
    let data = await api.Power.powerAll(payload);
    //Сортирует массив данных, чтобы отображалось корректно в выпадающем меню
    data.data = data.data.sort((item1, item2) => {
      if (item1.keyName < item2.keyName) return -1;
      if (item1.keyName > item2.keyName) return 1;
      return 0;
    });
    commit("SET_POWER", data);
  },
  ADD_POWER: async ({ commit }, payload) => {
    let data = await api.Power.addPower(payload);
    return data;
  },
  GET_POWER: async ({ commit }, payload) => {
    let data = await api.Power.getPower(payload);
    if (data.type == "success") {
      return data.data;
    }
  },
  EDIT_POWER: async ({ commit }, payload) => {
    let data = await api.Power.editPower(payload);
    return data;
  },
  DELETE_POWER: async ({ commit }, payload) => {
    let data = await api.Power.deletePower(payload);
    return data;
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
