import api from "@/api";
const name = "TIMETABLE";
const namespaced = true;
const state = {
  timtableAll: [],
  timeShedule: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_TIMETABLE: (state, data) => {
    if (data.type === "success") {
      state.timtableAll = data.data;
    }
  },
  SET_TIME_SHEDULE: (state, data) => {
    state.timeShedule = data;
  },
};
const actions = {
  GET_TIME_SHEDULE: async ({ commit }, payload) => {
    let data = await api.Timetable.getTimeShedule();
    commit("SET_TIME_SHEDULE", data);
  },
  GET_TIMETABLE: async ({ commit }, payload) => {
    let data = await api.Timetable.getTimetable(payload);
    commit("SET_TIMETABLE", data);
  },
  ADD_TIMETABLE: async ({ commit }, payload) => {
    let data = await api.Timetable.addTimetable(payload);
    return data;
  },
  GET_TIMETABLE_ID: async ({ commit }, payload) => {
    let data = await api.Timetable.getTimetableId(payload);
    if (data.type == "success") {
      return data.data;
    }
  },
  EDIT_TIMETABLE: async ({ commit }, payload) => {
    let data = await api.Timetable.editTimetable(payload);
    return data;
  },
  DELETE_TIMETABLE: async ({ commit }, payload) => {
    let data = await api.Timetable.deleteTimetable(payload);
    return data;
  },
  GET_COURIER_LIST_MAP: async ({ commit }, payload) => {
    let data = await api.Employee.getCourierListMap(payload);
    if (data.type == "success") return data.data;
    return [];
  },
  GET_COURIER_LIST_MAP_ORDER: async ({ commit }, payload) => {
    let data = await api.Employee.getCourierListMapOrder(payload);
    if (data.type == "success") return data.data;
    return [];
  },
  GET_COURIER: async ({ commit }, payload) => {
    let data = await api.Employee.getCourier(payload);
    if (data.type == "success") {
      return data.data;
    } else {
      return [];
    }
  },
  GET_PICKER: async ({ commit }, payload) => {
    let data = await api.Employee.getPicker(payload);
    if (data.type == "success") {
      return data.data;
    } else {
      return [];
    }
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
