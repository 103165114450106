import request from "../core/request";

export default {
  changeOrder(payload) {
    return request.execute(
      "put",
      `admin/providerCategory/edit/order/${payload.parentCategoryId}`,
      payload.body
    );
  },
  getCatalogCategoriesList() {
    return request.execute("get", "site/providerCategory/getAllMain");
  },
};
