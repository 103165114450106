/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
import Vue from "vue";
const name = "VACANCY";
const namespaced = true;
const state = {
  requirements: [],
  callbacks: [],
  conditions: [],
  tasks: [],
  vacancies: [],
  vacanciesTotalPages: null,
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_REQUIREMENTS: (state, data) => {
    if (data.type === "success") {
      state.requirements = data.data;
    }
  },
  PUSH_REQUIREMENT: (state, data) => {
    if (data.type === "success") {
      state.requirements.push(data.data);
    }
  },
  REMOVE_REQUIREMENT: (state, data) => {
    if (data.data.type === "success") {
      let removingIndex = state.requirements.findIndex(
        (el) => el.id === data.payload
      );
      state.requirements.splice(removingIndex, 1);
    } else {
      Vue.prototype.$notify({
        group: "app",
        type: "error",
        title: "Ошибка",
        text: data.data.text,
      });
    }
  },
  SET_CONDITIONS: (state, data) => {
    if (data.type === "success") {
      state.conditions = data.data;
    }
  },
  PUSH_CONDITION: (state, data) => {
    if (data.type === "success") {
      state.conditions.push(data.data);
    }
  },
  REMOVE_CONDITION: (state, data) => {
    if (data.data.type === "success") {
      let removingIndex = state.conditions.findIndex(
        (el) => el.id === data.payload
      );
      state.conditions.splice(removingIndex, 1);
    } else {
      Vue.prototype.$notify({
        group: "app",
        type: "error",
        title: "Ошибка",
        text: data.data.text,
      });
    }
  },
  SET_TASKS: (state, data) => {
    if (data.type === "success") {
      state.tasks = data.data;
    }
  },
  PUSH_TASK: (state, data) => {
    if (data.type === "success") {
      state.tasks.push(data.data);
    }
  },
  REMOVE_TASK: (state, data) => {
    if (data.data.type === "success") {
      let removingIndex = state.tasks.findIndex((el) => el.id === data.payload);
      state.tasks.splice(removingIndex, 1);
    } else {
      Vue.prototype.$notify({
        group: "app",
        type: "error",
        title: "Ошибка",
        text: data.data.text,
      });
    }
  },
  SET_CALLBACKS: (state, data) => {
    if (data.type === "success") {
      state.callbacks = data.data;
    }
  },
  PUSH_CALLBACK: (state, data) => {
    if (data.type === "success") {
      state.callbacks.push(data.data);
    }
  },
  REMOVE_CALLBACK: (state, data) => {
    if (data.data.type === "success") {
      let removingIndex = state.callbacks.findIndex(
        (el) => el.id === data.payload
      );
      state.callbacks.splice(removingIndex, 1);
    } else {
      Vue.prototype.$notify({
        group: "app",
        type: "error",
        title: "Ошибка",
        text: data.data.text,
      });
    }
  },
  SET_VACANCIES: (state, data) => {
    if (data.type === "success") {
      state.vacancies = data.data.content;
      state.vacanciesTotalPages = data.data.totalPages;
    }
  },
  REMOVE_VACANCY: (state, data) => {
    if (data.data.type === "success") {
      let removingIndex = state.vacancies.findIndex(
        (el) => el.id === data.payload
      );
      state.vacancies.splice(removingIndex, 1);
    }
  },
};
const actions = {
  GET_REQUIREMENTS: async ({ commit }) => {
    let data = await api.Vacancy.getRequirements();
    commit("SET_REQUIREMENTS", data);
  },
  ADD_REQUIREMENT: async ({ commit }, payload) => {
    let data = await api.Vacancy.addRequirement(payload);
    commit("PUSH_REQUIREMENT", data);
  },
  DELETE_REQUIREMENT: async ({ commit }, payload) => {
    let data = await api.Vacancy.deleteRequirement(payload);
    commit("REMOVE_REQUIREMENT", { data, payload });
  },
  GET_CONDITIONS: async ({ commit }) => {
    let data = await api.Vacancy.getConditions();
    commit("SET_CONDITIONS", data);
  },
  ADD_CONDITION: async ({ commit }, payload) => {
    let data = await api.Vacancy.addCondition(payload);
    commit("PUSH_CONDITION", data);
  },
  DELETE_CONDITION: async ({ commit }, payload) => {
    let data = await api.Vacancy.deleteCondition(payload);
    commit("REMOVE_CONDITION", { data, payload });
  },
  GET_TASKS: async ({ commit }) => {
    let data = await api.Vacancy.getTasks();
    commit("SET_TASKS", data);
  },
  ADD_TASK: async ({ commit }, payload) => {
    let data = await api.Vacancy.addTask(payload);
    commit("PUSH_TASK", data);
  },
  DELETE_TASK: async ({ commit }, payload) => {
    let data = await api.Vacancy.deleteTask(payload);
    commit("REMOVE_TASK", { data, payload });
  },
  GET_CALLBACKS: async ({ commit }) => {
    let data = await api.Vacancy.getCallbacks();
    commit("SET_CALLBACKS", data);
  },
  ADD_CALLBACK: async ({ commit }, payload) => {
    let data = await api.Vacancy.addCallback(payload);
    commit("PUSH_CALLBACK", data);
  },
  DELETE_CALLBACK: async ({ commit }, payload) => {
    let data = await api.Vacancy.deleteCallback(payload);
    commit("REMOVE_CALLBACK", { data, payload });
  },
  GET_VACANCIES: async ({ commit }, payload) => {
    let request = {
      page: payload.page || 0,
      size: payload.size || 20,
    };
    let data = await api.Vacancy.getAllVacancies(request);
    commit("SET_VACANCIES", data);
  },
  EDIT_VACANCY: async ({ commit }, payload) => {
    let data = await api.Vacancy.editVacancy(payload);
  },
  DELETE_VACANCY: async ({ commit }, payload) => {
    let data = await api.Vacancy.deleteVacancy(payload);
    commit("REMOVE_VACANCY", { data, payload });
  },
  ADD_VACANCY: async ({ commit }, payload) => {
    let data = await api.Vacancy.addVacancy(payload);
    return data;
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
