/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
import Vue from "vue";
const name = "ADDRESS";
const namespaced = true;
const state = {
  allAddress: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_ADDRESS_ALL: (state, data) => {
    if (data.type === "success") {
      state.allAddress = data.data;
    }
  },
};
const actions = {
  GET_ALL_ADDRESS: async ({ commit }, payload) => {
    let data = await api.Address.addressAll(payload);
    commit("SET_ADDRESS_ALL", data);
  },
  ADD_ADDRESS: async ({ commit }, payload) => {
    let data = await api.Address.addAddress(payload);
    return data;
  },
  GET_ADDRESS: async ({ commit }, payload) => {
    let data = await api.Address.getAddress(payload);
    if (data.type == "success") {
      return data.data;
    }
  },
  EDIT_ADDRESS: async ({ commit }, payload) => {
    let data = await api.Address.editAddress(payload);
    return data;
  },
  DELETE_ADDRESS: async ({ commit }, payload) => {
    let data = await api.Address.deleteAddress(payload);
    return data;
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
