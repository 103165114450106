import request from "../core/request";

export default {
  departmentAll(id) {
    return request.execute("get", `/admin/department/all/city/${id}`);
  },
  pageDepartment() {
    return request.execute("get", `admin/department/all/1`);
  },
  //body: { "name" : "филала", "scheduleId" : 1, "addressId" : 30, "cityId" : 1, "selfZoned" : false, isAyan: false }
  addDepartment(body) {
    return request.execute("post", "/admin/department/add", body);
  },
  //body:{ "id" : 498, "name" : "филала", "scheduleId" : 1, "addressId" : 30, "cityId" : 1, "selfZoned" : false, isAyan: false }
  editDepartment(body) {
    return request.execute("put", "/admin/department/edit", body);
  },
  getDepartment(id) {
    return request.execute("get", `/admin/department/${id}`);
  },
  //body: { "cityId" : 1, "page" : 0, "pageSize" : 10 }
  getAllDepartment(body) {
    return request.execute("post", `/admin/department/city/departments`, body);
  },
  deleteDepartment(departmentId) {
    return request.execute(
      "delete",
      `/admin/department/delete/${departmentId}`
    );
  },
  setCouriersZone(payload) {
    return request.execute(
      "put",
      `/admin/deliveryZoneDepartment/set/${payload.departmentId}/${payload.deliveryZoneId}`
    );
  },
  addRequisits(body) {
    return request.execute("post", `/admin/department/add/requisites`, body);
  },
  changeRequisits(body) {
    return request.execute("put", `/admin/department/edit/requisites`, body);
  },
};
