import request from "../core/request";

export default {
  getDepartmentWorkTime(departmentId) {
    return request.execute(
      "get",
      `admin/department/get/timeTable/${departmentId}`
    );
  },
  deleteDepartmentWorkTime(departmentWorkTimeId) {
    return request.execute(
      "delete",
      `department/delete/${departmentWorkTimeId}`
    );
  },
  addDepartmentWorkTime(data) {
    return request.execute("post", "department/add", data);
  },
  editDay(data) {
    return request.execute("put", "admin/department/edit/workingDay", data);
  },
  createWeek(data) {
    return request.execute("post", "admin/department/add/timeTable", data);
  },
};
