import request from "../core/request";

export default {
  productsPagination(input) {
    return request.execute(
      "put",
      "/site/provider/product/get/catalog/list/site",
      input
    );
  },
  addCategoryToProduct(payload) {
    return request.execute(
      "post",
      `/admin/showcase/add/product/category/${payload.categoryId}/${payload.productId}`
    );
  },
  addProductToCategory(payload) {
    return request.execute(
      "post",
      `admin/showcase/add/product/${payload.categoryId}/${payload.productId}`
    );
  },
  removeCategoryFromProduct(payload) {
    return request.execute(
      "delete",
      `/admin/showcase/delete/product/category/${payload.categoryId}/${payload.productId}`
    );
  },
  removeProductFromCategory(payload) {
    return request.execute(
      "delete",
      `admin/showcase/delete/product/${payload.categoryId}/${payload.productId}`
    );
  },

  getProduct(productId) {
    return request.execute("get", `/admin/provider/product/get/${productId}`);
  },
  //удаление продукта body {deleteReason:string, productId:number}
  deleteProduct(body) {
    return request.execute("post", `admin/product/delete`, body);
  },
  addProductImages(data) {
    return request.execute(
      "post",
      `admin/provider/product/add/image/${data.id}`,
      data.files
    );
  },
  addProductImagesByProvider(data) {
    return request.execute(
      "post",
      `admin/moderatorProviderProducts/add/image/${data.id}`,
      data.files
    );
  },
  deleteProductImage(data) {
    return request.execute(
      "delete",
      `admin/provider/product/delete/image/${data.productId}/${data.imageId}`
    );
  },
  editProduct(input) {
    return request.execute("put", "admin/provider/product/edit", input);
  },
  createProduct(body) {
    return request.execute("post", "/provider/product", body);
  },
  getAllMinimalQuantity() {
    return request.execute("get", `admin/minimalQuantity/get/all`);
  },
  getProductMinimalQuantity(productId) {
    return request.execute(
      "get",
      `admin/minimalQuantity/get/byId/${productId}`
    );
  },
  addQuantity(input) {
    return request.execute("post", "admin/minimalQuantity/add", input);
  },
  editQuantity(input) {
    return request.execute("put", "admin/minimalQuantity/edit", input);
  },
  productFind(input) {
    return request.execute("post", "admin/product/find/", input);
  },

  // MVP
  getProductsOfShowcaseCategory(input) {
    return request.execute("get", `admin/showcase/get/products/${input}`);
  },
  getCategoryProducts(input) {
    return request.execute("get", `admin/provider/product/products/${input}`);
  },
  productsPaginationMVP(body) {
    return request.execute(
      "post",
      "/admin/provider/product/get/without/category/all",
      body
    );
  },
  // body{"productId": number,"deleteReason": stirng}
  deleteMVPProduct(body) {
    return request.execute("post", "/admin/provider/product/delete", body);
  },
  productsPaginationMVPWithCategoryes(body) {
    return request.execute(
      "post",
      `/admin/provider/product/get/category/all`,
      body
    );
  },
  // body{"productId": number,"limit": number}
  addLimitToProduct(body) {
    return request.execute("post", "/admin/basket/product/limit/add", body);
  },
  removeLimitFromProduct(id) {
    return request.execute(
      "delete",
      `/admin/basket/product/limit/delete/${id}`
    );
  },
  // MVP поисковые запросы
  findMVPProductWithCategoryByBarcode(barcode) {
    return request.execute(
      "get",
      `admin/provider/product/find/byBarcode/byCategory/${barcode}`
    );
  },
  findMVPProductWithoutCategoryByBarcode(barcode) {
    return request.execute(
      "get",
      `admin/provider/product/find/byBarcode/withoutCategory/${barcode}`
    );
  },
  findMVPProductWithCategoryByName(body) {
    return request.execute(
      "post",
      `admin/provider/product/find/byName/byCategory`,
      body
    );
  },
  findMVPProductWithoutCategoryByName(body) {
    return request.execute(
      "post",
      `admin/provider/product/find/byName/withoutCategory`,
      body
    );
  },
  // body { "categoryId": 97, "page": 0,  "pageSize": 10 }
  findMVPProductByCategory(body) {
    return request.execute(
      "post",
      `admin/provider/product/get/products/with/category`,
      body
    );
  },
  findMVPProductByProvider(body) {
    return request.execute(
      "post",
      `admin/provider/product/find/byProvider`,
      body
    );
  },
  // {"productIds": [799102, 799103],"categoryIds": [199, 45, 46],"saveCategories": false}
  addCategoryToManyProducts(body) {
    return request.execute(
      "post",
      `/admin/showcase/add/product/category/all`,
      body
    );
  },
  changeShowcaseCategoryProductsOrder(input) {
    return request.execute(
      "put",
      `admin/showcase/edit/product/order/${input.categoryId}`,
      input.body
    );
  },
  // модерация
  getProductsForModeration(body) {
    return request.execute(
      "post",
      `admin/moderatorProviderProducts/provider/filter`,
      body
    );
  },
  moderateProducts(body) {
    return request.execute("put", `admin/moderatorProviderProducts`, body);
  },
  getProductDifference(id) {
    return request.execute(
      "get",
      `admin/moderatorProviderProducts/history?request=${id}`
    );
  },
  // изменение цен остатков и доступности продукта у поставщика
  changeProductPrices(input) {
    return request.execute(
      "put",
      `admin/moderatorProviderProducts/price-remaining?departmentId=${input.departmentId}&providerId=${input.providerId}`,
      input.body
    );
  },
  // добавление продукта в продукты поставщика
  addProductToProvider(input) {
    return request.execute(
      "post",
      `admin/moderatorProviderProducts/${input.providerId} `,
      input.body
    );
  },
  // Поиск для поставщиков, среди всех продуктов этого поставщика
  findProviderProducts(input) {
    return request.execute(
      "post",
      `admin/moderatorProviderProducts/find/byName/byProvider`,
      input
    );
  },
};
