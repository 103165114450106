import request from "../core/request";

export default {
  editImage(input) {
    return request.execute(
      "put",
      `admin/providerCategory/edit/img/${input.categoryId}`,
      input.input
    );
  },
  addNewCategory(payload) {
    return request.execute("post", "/admin/providerCategory/add", payload);
  },
  //   {
  //     "id": 170354, - обязательный параметр
  //     "name": "Спортивные товары",
  //     "nameKz": "Спортивные товары kz",
  //     "description": "Спортивные товары описание",
  //     "descriptionKz": "Спортивные товары описание kz",
  //     "parentId": 0,
  //     "hidden": false,
  //     "deleted": false
  // }
  updateCategory(payload) {
    return request.execute("put", "/admin/providerCategory/edit", payload);
  },
  updateCategoryOfProduct(payload) {
    return request.execute(
      "post",
      `/admin/product/editCategory/${payload.productId}`,
      payload.categoryIds
    );
  },
  categoryAll() {
    return request.execute("get", `/admin/providerCategory/all/list`);
  },
  // только подкатегории без основных
  categoryForProducts() {
    return request.execute(
      "get",
      `/admin/providerCategory/all/promo-and-subcategories`
    );
  },
  categoryPagination(input) {
    return request.execute("post", "/admin/providerCategory/all", input);
  },
  deleteCategory(id) {
    return request.execute("delete", `admin/providerCategory/delete/${id}`);
  },
  addCategory(input) {
    return request.execute("post", "admin/category/add", input);
  },
  getCategory(id) {
    return request.execute("get", `/admin/providerCategory/${id}`);
  },
  searchCategory(name) {
    return request.execute("get", `/admin/providerCategory/find/${name}`);
  },
  // MVP
  addCategoryOnVitrin(id) {
    return request.execute("post", `admin/showcase/add/category/${id}`);
  },
  deleteCategoryOnVitrin(id) {
    return request.execute("delete", `admin/showcase/delete/category/${id}`);
  },
  getAllCategoryOnVitrin() {
    return request.execute("get", `admin/showcase/get/category/filtered`);
  },
  changeShowcaseCategoriesOrder(input) {
    return request.execute("put", "admin/showcase/edit/category/order", input);
  },
  deleteCategoryImg(catId) {
    return request.execute(
      "delete",
      `admin/providerCategory/delete/img/${catId}`
    );
  },
  downloadCategory() {
    return request.executeFile(
      "get",
      "admin/providerCategory/all/tree-categories"
    );
  },
};
