import api from "@/api";
const name = "ORDERSTATISTICSMAP";
const namespaced = true;
const state = {
  polygons: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_POLYGONS: (state, data) => {
    if (data.type === "success") {
      state.polygons = data.data;
    }
  },
};
const actions = {
  GET_POLYGONS: async ({ commit }, output) => {
    //   null - for default value, null - unrequired
    let input = {
      cityId: output.cityId,
      departmentId: output.departmentId,
      startDate: output.startDate || null,
      endDate: output.endDate || null,
      orderStatusId: output.orderStatusId || null,
    };
    let data = await api.OrderStatisticsMap.getPolygons(input);
    commit("SET_POLYGONS", data);
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
