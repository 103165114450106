<template>
  <div>
    <component :is="layout">
      <router-view />
    </component>
    <notifications
      position="bottom right"
      group="app"
      :width="300"
      :duration="3000"
      ignore-duplicates
    />
  </div>
</template>
<script>
import DefaultLayout from "@/layouts/default";
import AuthLayout from "@/layouts/auth";
import ChatLayout from "@/layouts/chat";
import AstorLayout from "@/layouts/authASTOR";
export default {
  name: "App",
  components: {
    "default-layout": DefaultLayout,
    "auth-layout": AuthLayout,
    "chat-layout": ChatLayout,
    "astor-layout": AstorLayout,
  },
  computed: {
    layout() {
      return this.$route.meta.layout
        ? `${this.$route.meta.layout}-layout`
        : "default-layout";
    },
  },
};
</script>
<style lang="scss">
.notification-title {
  font-size: 1.1rem;
  font-weight: 500;
}
.notification-content {
  font-size: 1rem;
}
.header {
  background: linear-gradient(to right, #e93e7b 50%, white 70%);
  margin-left: -12px;
  color: white;
  padding: 5px 10px 5px 70px;
  width: 100%;
  @media (max-width: 500px) {
    margin-left: 0px;
    padding: 5px;
    justify-content: center;
    background: linear-gradient(90deg, #e93e7b 70%, white 90%);
  }
}
</style>
