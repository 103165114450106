/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";

const name = "DEPARTMENT";
const namespaced = true;
const state = {
  cityDepartment: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_DEPARTMENT_LIST: (state, data) => {
    if (data.type === "success") {
      state.cityDepartment = data.data;
    }
  },
};
const actions = {
  GET_DEPARTMENT: async ({ commit }, payload) => {
    let data = await api.Department.departmentAll(payload);
    commit("SET_DEPARTMENT_LIST", data);
  },
  PAGE_DEPARTMENT: async ({ commit }, payload) => {
    let data = await api.Department.pageDepartment(payload);
    return data.data.content;
  },
  ADD_DEPARTMENT: async ({ commit }, payload) => {
    let data = await api.Department.addDepartment(payload);
    return data;
  },
  EDIT_DEPARTMENT: async ({ commit }, payload) => {
    let data = await api.Department.editDepartment(payload);
    return data;
  },
  GET_SINGLE: async ({ commit }, payload) => {
    let data = await api.Department.getDepartment(payload);
    return data;
  },
  DELETE_DEPARTMENT: async ({ commit }, payload) => {
    let data = await api.Department.deleteDepartment(payload);
    return data;
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
