/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
const name = "COURIERS";
const namespaced = true;

const state = {
  allTypes: [],
  allCouriers: [],
  allDeliveryZones: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_COURIERS_LIST: (state, data) => {
    state.allCouriers = data;
  },
  SET_COURIERS_TYPES_LIST: (state, data) => {
    state.allTypes = data;
  },
  SET_DELIVERY_ZONES: (state, data) => {
    state.allDeliveryZones = data;
  },
};

const actions = {
  GET_ALL_COIRIERS: async ({ commit }) => {
    let data = await api.Couriers.allCouriersList();
    commit("SET_COURIERS_LIST", data);
  },
  GET_ALL_DELIVERY_ZONES: async ({ commit }) => {
    let data = await api.Couriers.getAllDeliveryZones();
    commit("SET_DELIVERY_ZONES", data);
  },
  GET_ALL_COIRIERS_TYPES: async ({ commit }) => {
    let data = await api.Couriers.allCouriersTypes();
    commit("SET_COURIERS_TYPES_LIST", data);
  },
  ADD_COURIER_TYPE: async ({ commit }, payload) => {
    let data = await api.Couriers.addCourierType(payload);
    return data;
  },
  EDIT_COURIER_TYPE: async ({ commit }, payload) => {
    let data = await api.Couriers.editCourierType(payload);
    return data;
  },
  CREATE_COURIER_TYPE: async ({ commit }, payload) => {
    let data = await api.Couriers.createCourierType(payload);
    return data;
  },
  DELETE_COURIER_TYPE: async ({ commit }, payload) => {
    let data = await api.Couriers.deleteCourierType(payload);
    return data;
  },
  ADD_DELIVERY_ZONE: async ({ commit }, payload) => {
    let data = await api.Couriers.addDeliveryZone(payload);
    return data;
  },
  GET_DELIVERY_ZONE_DEPARTMENTS: async ({ commit }) => {
    let data = await api.Couriers.getAllDeliveryZoneDepartment();
    return data;
  },
  EDIT_DELIVERY_ZONE: async ({ commit }, payload) => {
    let data = await api.Couriers.editDeliveryZone(payload);
    return data;
  },
  DELETE_DELIVERY_ZONE: async ({ commit }, payload) => {
    let data = await api.Couriers.deleteDeliveryZone(payload);
    return data;
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
