/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
import Vue from "vue";
const name = "LABEL";
const namespaced = true;
const state = {
  allLabel: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_LABEL_ALL: (state, data) => {
    if (data.type === "success") {
      state.allLabel = data.data;
    }
  },
};
const actions = {
  GET_ALL_LABEL: async ({ commit }, payload) => {
    let data = await api.Label.labelAll();
    commit("SET_LABEL_ALL", data);
  },
  ADD_LABEL: async ({ commit }, payload) => {
    let data = await api.Label.addLabel(payload);
    return data;
  },
  GET_LABEL: async ({ commit }, payload) => {
    let data = await api.Label.getLabel(payload);
    if (data.type == "success") {
      return data.data;
    }
  },
  EDIT_LABEL: async ({ commit }, payload) => {
    let data = await api.Label.editLabel(payload);
    return data;
  },
  DELETE_LABEL: async ({ commit }, payload) => {
    let data = await api.Label.deleteLabel(payload);
    return data;
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
