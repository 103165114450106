<template>
  <v-app style="background: linear-gradient(to right, #5d4bd0, #e93e7b)">
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>
<script>
export default {
  async created() {
    if (this.$cookie.get("token") !== null) {
      this.$router.push("/");
    }
  },
};
</script>
