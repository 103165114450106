import request from "../core/request";

export default {
  getAllFeatures() {
    return request.execute("get", `admin/feature/allForFeature`);
  },
  createFeature(data) {
    return request.execute("post", `admin/feature/createFeature`, data);
  },
  editFeature(data) {
    return request.execute(
      "put",
      `admin/feature/updateFeature/${data.id}`,
      data
    );
  },
  deleteFeature(featureId) {
    return request.execute(
      "delete",
      `admin/feature/deleteFeature/${featureId}`
    );
  },
  getAllGroups() {
    return request.execute("get", `admin/feature/allForFeatureGroup`);
  },
  replaceGroup(payload) {
    return request.execute(
      "put",
      `admin/feature/changeFeatureGroup/${payload.id}/${payload.newId}`
    );
  },
  createGroup(data) {
    return request.execute("post", `admin/feature/createFeatureGroup`, data);
  },
  editGroup(data) {
    return request.execute(
      "put",
      `admin/feature/updateFeatureGroup/${data.id}`,
      data.body
    );
  },
  bindGroupWithFeature(data) {
    return request.execute(
      "post",
      `admin/feature/addFeatureToGroup/${data.featureId}/${data.groupId}`,
      data
    );
  },
  getCategoryFeatures(categoryIds) {
    const params = categoryIds.reduce((accum, id) => {
      const params = `categoryIds=${id}&`;
      accum += params;
      return accum;
    }, "");
    const paramsId = `?${params.slice(0, -1)}`;
    return request.execute(
      "get",
      `admin/feature/featureByCategoryIds${paramsId}`
    );
  },
  addFeatureToProduct(data) {
    return request.execute(
      "post",
      `admin/feature/addValueToProduct/${data.productId}`,
      data.body
    );
  },
};
