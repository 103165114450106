/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
const name = "Providers";
const namespaced = true;

const state = {
  allProviders: [],
  providerProducts: [],
};

const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_PROVIDERS: (state, data) => {
    state.allProviders = data;
  },
  SET_PRODUCTS: (state, data) => {
    state.providerProducts = data;
  },
};
const actions = {
  GET_ALL_PROVIDERS: async ({ commit }) => {
    const data = await api.Providers.getAllProviders();
    commit("SET_PROVIDERS", data);
  },
  GET_PROVIDER_PRODUCTS: async ({ commit }, payload) => {
    let data = await api.Providers.getProviderProducts(payload);
    if (data?.type === "error") {
      data = [];
    }
    commit("SET_PRODUCTS", data);
  },
  GET_PROVIDER_PRODUCTS_WITH_FILTER: async ({ commit }, payload) => {
    let data = await api.Providers.getProviderProductsWithFilter(payload);
    if (data?.type === "error") {
      data = [];
    }
    commit("SET_PRODUCTS", data);
  },
  DELETE_PROVIDER: async ({ commit }, payload) => {
    return await api.Providers.deleteProvider(payload);
  },
  ADD_PROVIDER: async ({ commit }, payload) => {
    return await api.Providers.addProvider(payload);
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
