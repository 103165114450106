export default class singleorder {
  constructor() {
    this.input = {
      address: {
        addressTypeId: null,
        block: "",
        city: null,
        code: "",
        geo: "",
        house: "",
        id: null,
        level: "",
        room: "",
        stairs: "",
        street: "",
      },
      comment: "",
      deliveryTime: null,
      departmentIds: [],
      departmentId: 0,
      device: "Admin",
      orderId: null,
      nameUser: "",
      paymentTypeId: 1,
      phoneUser: "",
      products: [],
      promocode: "",
      statusId: 1,
      surnameUser: "",
      isSoloOrder: true,
    };
    this.body = [];
  }
  setInput() {
    return this.input;
  }
  setData(data) {
    this.input.orderId = data.id;
    this.input.address = data.address.address;
    this.input.address.addressTypeId = data.address.address.addressType.id;
    this.input.address.city = data.address.address.city.id;
    this.input.comment = data.comment;
    this.input.deliveryTime = data.dateDelivery.replace("T", " ");
    this.input.device = data.device;
    this.input.nameUser = data.client.name;
    this.input.paymentTypeId = data.paymentType.id;
    this.input.phoneUser = data.client.phone;
    this.input.products = data.products;
    this.input.products.forEach((value) => {
      value.productId = value.product.id;
      value.image = value.product.images.length
        ? value.product.images[0].fileUrl
        : "";
      value.providerId = value.provider.id;
    });
    this.input.promocode = data.promoCode;
    this.input.statusId = data.status.id;
    this.input.surnameUser = data.client.surname;
    this.input.departmentIds = [data.department.id];
    this.input.departmentId = data.department.id;
    return this.input;
  }
  setInputSearch(product) {
    let input = {
      countPages: product.pageable.pageSize,
      currentPage: product.pageable.pageNumber,
      products: product.content.map(function (x) {
        return {
          id: x.id,
          productId: {
            name: x.name,
            images: [{ imageId: { fileUrl: product.img } }],
          },
          price: x.price,
        };
      }),
      totalElements: product.totalElements,
      totalPages: product.totalPages,
    };
    return input;
  }
}
