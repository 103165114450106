import request from "../core/request";

export default {
  getTimetable(id) {
    return request.execute(
      "get",
      `admin/employee_schedule/department/list/${id}`
    );
  },
  addTimetable(input) {
    return request.execute("post", "admin/employee_schedule/add", input);
  },
  getTimetableId(id) {
    return request.execute("post", `admin/employee_schedule/get/${id}`);
  },
  editTimetable(input) {
    return request.execute("put", "admin/employee_schedule/update", input);
  },
  deleteTimetable(id) {
    return request.execute("delete", `admin/employee_schedule/delete/${id}`);
  },
  getCourierListMap() {
    return request.execute("get", "admin/courier/all");
  },
  getCourierListMapOrder(data) {
    return request.execute("get", `admin/courier/order/all/${data.id}`);
  },
  getCourier(id) {
    return request.execute("get", `admin/courier/all/city/${id}`);
  },
  getPicker(id) {
    return request.execute("get", `admin/picker/all/city/${id}`);
  },

  //новый график работы
  getTimeShedule() {
    return request.execute("get", `admin/shift/timeTable/all`);
  },
  createTimeShedule(body) {
    return request.execute("post", `admin/shift/timeTable/add`, body);
  },
  assignTimeShedule(body) {
    return request.execute("post", `admin/shift/timeTable/assign/shifts`, body);
  },
  assignTimeTable(payload) {
    return request.execute(
      "get",
      `admin/shift/set/timeTable/${payload.employeeId}/${payload.timeTableId}`
    );
  },
  deleteTimeShedule(timeTableId) {
    return request.execute(
      "delete",
      `admin/shift/timeTable/delete/${timeTableId}`
    );
  },
};
