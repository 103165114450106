/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
import Vue from "vue";
const name = "ROLE";
const namespaced = true;
const state = {
  allRoles: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_ROLE: (state, data) => {
    if (data.type === "success") {
      state.allRoles = data.data;
    }
  },
};
const actions = {
  GET_ALL_ROLES: async ({ commit }, payload) => {
    let data = await api.Role.roleAll(payload);
    commit("SET_ROLE", data);
  },
  GET_ROLE: async ({ commit }, payload) => {
    let data = await api.Role.getRole(payload);
    return data;
  },
  ADD_ROLE: async ({ commit }, payload) => {
    let data = await api.Role.addRole(payload);
    return data;
  },
  EDIT_ROLE: async ({ commit }, payload) => {
    let data = await api.Role.editRole(payload);
    return data;
  },
  DELETE_ROLES: async ({ commit }, payload) => {
    let data = await api.Role.deleteRole(payload);
    return data;
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
