/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";

const name = "BANNER";
const namespaced = true;
const state = {
  allBanners: [],
};
const getters = {
  STATE: (state) => state,
};
const mutations = {
  SET_BANNERS: (state, data) => {
    state.allBanners = data;
  },
  UPDATE_BANNERS_POSITION: (state, data) => {
    let allBannersArray = data.map((el) => {
      el.position = data.indexOf(el) + 1;
      return el;
    });
    state.allBanners = allBannersArray;
  },
  REMOVE_BANNER: (state, bannerId) => {
    let removedBannerIndex;
    state.allBanners.forEach((el) => {
      if (el.id === bannerId) {
        removedBannerIndex = el.position;
      }
    });
    state.allBanners.splice(removedBannerIndex - 1, 1);
  },
  REMOVE_BANNERS: (state, bannerIds) => {
    bannerIds.forEach((bannerId) => {
      let removedBannerIndex = state.allBanners.findIndex((el) => {
        if (el.id === bannerId) return el.index;
      });
      state.allBanners.splice(removedBannerIndex, 1);
    });
  },
};
const actions = {
  GET_BANNER_LIST_BY_DEPARTMENT: async ({ commit }, departmentId) => {
    let data = await api.Banner.getBannerList(departmentId);
    commit("SET_BANNERS", data.data);
  },
  GET_CATEGORY_BANNERS: async ({ commit }, cityId) => {
    let data = await api.Banner.getCategoryBanners(cityId);
    commit("SET_BANNERS", data.data);
  },
  UPDATE_BANNER_LIST: async () => {
    let data = await api.Banner.updateSeveralBanners(state.allBanners);
    return data;
  },
  DELETE_BANNER: async ({ commit }, bannerId) => {
    let data = await api.Banner.deleteBanner(bannerId);
    if (data.type === "success") {
      commit("REMOVE_BANNER", bannerId);
      return true;
    } else {
      return false;
    }
  },
  GET_BANNER_IMAGE: async ({ commit }, input) => {
    let data = await api.Banner.getBannerImage(input);
    if (data.type === "success") {
      return data;
    }
  },
  CREATE_BANNER: async ({ commit }, input) => {
    let localInput = {
      name: input.name,
      departmentId: input.departmentId,
    };
    let data = await api.Banner.createBanner(localInput);
    return data;
  },
  ADD_BANNER_IMAGE: async ({ commit }, input) => {
    const localFile = [];
    if (input.mobile === undefined) {
      input.mobile = [];
      input.mobile.name = false;
    } else localFile.push(input.mobile);

    if (input.desktop === undefined) {
      input.desktop = [];
      input.desktop.name = false;
    } else localFile.push(input.desktop);

    let localFormData = new FormData();
    localFile.forEach((element) => {
      localFormData.append(
        "file",
        element,
        input.departmentId + input.name + element.name
      );
    });
    let localInput = {
      bannerId: input.id,
      desktopImageName: input.departmentId + input.name + input.desktop.name,
      mobileImageName: input.departmentId + input.name + input.mobile.name,
      file: localFormData,
    };
    let data = await api.Banner.addImageForBanner(localInput);
    return data.type;
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
