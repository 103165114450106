import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import Category from "./modules/Category";
import Employee from "./modules/Employee";
import Clients from "./modules/Clients";
import Address from "./modules/Address";
import Label from "./modules/Label";
import Logistic from "./modules/Logistic";
import Auth from "./modules/Auth";
import City from "./modules/City";
import Atypes from "./modules/Atypes";
import Measure from "./modules/Measure";
import Order from "./modules/Order";
import Status from "./modules/Status";
import Products from "./modules/Product";
import Payment from "./modules/Payment";
import Department from "./modules/Department";
import Schedule from "./modules/Schedule";
import Currency from "./modules/Currency";
import Coupons from "./modules/Coupons";
import Power from "./modules/Power";
import Role from "./modules/Role";
import RolePower from "./modules/RolePower";
import User from "./modules/User";
import Chat from "./modules/Chat";
import Reports from "./modules/Reports";
import File from "./modules/File";
import Timetable from "./modules/Timetable";
import Zone from "./modules/Zone";
import Static from "./modules/Static";
import Shelf from "./modules/Shelf";
import Banner from "./modules/Banner";
import Catalog from "./modules/Catalog";
import Astor from "./modules/Astor";
import Phone from "./modules/Phone";
import TimeControl from "./modules/TimeControl";
import OrderStatisticsMap from "./modules/OrderStatisticsMap";
import FlexParameter from "./modules/flexParameter";
import Features from "./modules/Features";
import Vacancy from "./modules/Vacancy";
import StackSystem from "./modules/StackSystem";
import Reviews from "./modules/Reviews";
import Providers from "./modules/Providers";
import Couriers from "./modules/Couriers";
import PushNotifications from "./modules/PushNotifications";
import Education from "./modules/Education";
import Penalty from "./modules/Penalty";
import ProviderGroups from "./modules/ProviderGroups";

const MODULES = {
  StackSystem,
  Category,
  Employee,
  Clients,
  Address,
  Label,
  Logistic,
  Auth,
  City,
  Atypes,
  Measure,
  Order,
  Status,
  Products,
  Providers,
  Payment,
  PushNotifications,
  ProviderGroups,
  Department,
  Schedule,
  Currency,
  Coupons,
  Power,
  Penalty,
  Role,
  RolePower,
  User,
  Chat,
  Reports,
  File,
  Timetable,
  Zone,
  Static,
  Shelf,
  Banner,
  Catalog,
  Astor,
  Phone,
  TimeControl,
  OrderStatisticsMap,
  FlexParameter,
  Features,
  Vacancy,
  Reviews,
  Couriers,
  Education,
};

export default new Vuex.Store({
  modules: MODULES,
});
