import api from "@/api";
const name = "StackSystem";
const namespaced = true;
const state = {
  stacks: [], // место хранения (стеллаж) по филиалу
  shelves: [], // полка стеллажа
  shelfProducts: [], // связь товара с полкой
  stackTypes: [], // Кэшированный запрос (редко требует обновления)
  excludedQueue: [], // Очередь исключений
};
const getters = {
  STATE: (s) => s,
  STACKS: (s) => s.stacks,
  SHELVES: (s) => s.shelves,
  SHELF_PRODUCT: (s) => s.shelfProducts,
  STACK_TYPES: (s) => s.stackTypes,
  EXCLUDED_QUEUE: (s) => s.excludedQueue,
};
const mutations = {
  SET_CURRENT_DEPARTMENT_STACKS: (state, data) => {
    state.stacks = data;
  },
  SET_CURRENT_STACK_SHELVES: (state, data) => {
    state.shelves = data;
  },
  SET_SHELF_PRODUCTS: (state, data) => {
    state.shelfProducts = data;
  },
  SET_STACK_TYPES: (state, data) => {
    state.stackTypes = data;
  },
  SET_EDITTED_STACK: (state, data) => {},
  SET_EXCLUDED_QUEUE: (state, data) => {
    state.excludedQueue = data;
  },
};
// TODO: на данный момент коммита, еще не реализован весь функционал для этой части прилжоения,
// так что при новой реализации и добавлении новых задач связанных с этим файлом и работой со стеллажами,
// доработать эту логику с коммитами (они закомменчены), думаю итак понятно что нужно делать
const actions = {
  GET_STACKS_BY_DEPARTMENT: async ({ commit }, payload) => {
    let data = await api.StackSystem.getAllStacksByDepartment(payload);
    commit("SET_CURRENT_DEPARTMENT_STACKS", data);
    return data;
  },
  GET_SHELVES_BY_STACK: async ({ commit }, payload) => {
    let data = await api.StackSystem.getShelvesByStackId(payload);
    commit("SET_CURRENT_STACK_SHELVES", data);
    return data;
  },
  GET_SHELF_PRODCUTS_BY_SHELF: async ({ commit }, payload) => {
    let data = await api.StackSystem.getShelfProductsByShelfId(payload);
    commit("SET_SHELF_PRODUCTS", data);
    return data;
  },

  ADD_NEW_STACK: async ({ commit }, payload) => {
    let data = await api.StackSystem.addNewStack(payload);
    // commit("SET_NEW_STACK", data);
    return data;
  },
  EDIT_STACK_BY_ID: async ({ commit }, payload) => {
    let data = await api.StackSystem.editStackById(
      payload.stackId,
      payload.input
    );
    // commit("SET_EDITTED_STACK", data);
    return data;
  },
  DELETE_STACK: async ({ commit }, payload) => {
    let data = await api.StackSystem.deleteStackById(payload);
    // commit("DELETE_STACK", payload);
    return data;
  },

  ADD_NEW_SHELF: async ({ commit }, payload) => {
    let data = await api.StackSystem.addNewShelf(payload);
    // commit("SET_NEW_SHELF", data);
    return data;
  },
  EDIT_SHELF_BY_ID: async ({ commit }, payload) => {
    let data = await api.StackSystem.editShelfById(
      payload.shelfId,
      payload.input
    );
    // commit("SET_EDITTED_SHELF", data);
    return data;
  },
  DELETE_SHELF: async ({ commit }, payload) => {
    let data = await api.StackSystem.deleteShelfById(payload);
    // commit("DELETE_SHELF", payload);
    return data;
  },

  DELETE_SEVERAL_PRODUCTS: async ({ commit }, payload) => {
    let data = await api.StackSystem.deleteSeveralShelfProductsById(payload);
    // commit("DELETE_SEVERAL_PRODUCTS", payload)
    return data;
  },

  GET_ALL_STACK_TYPES: async ({ commit }) => {
    let data = await api.StackSystem.getAllStackTypes();
    commit("SET_STACK_TYPES", data);
    return data;
  },
  ADD_NEW_STACK_TYPE: async ({ commit }, payload) => {
    let data = await api.StackSystem.addNewStackType(payload);
    // commit("SET_NEW_STACK_TYPE", data);
    return data;
  },
  EDIT_STACK_TYPE: async ({ commit }, payload) => {
    let data = await api.StackSystem.editStackTypeById(
      payload.stackTypeId,
      payload.input
    );
    // commit("SET_EDITTED_STACK_TYPE", data);
    return data;
  },
  DELETE_STACK_TYPE: async ({ commit }, payload) => {
    let data = await api.StackSystem.deleteStackTypeById(payload);
    // commit("DELETE_STACK_TYPE", data);
    return data;
  },

  GET_EXCLUDED_PARAMS: async ({ commit }) => {
    let data = await api.StackSystem.getAllExcludedParams();
    commit("SET_EXCLUDED_QUEUE", data);
    return data;
  },
  ADD_CATEGORY_INTO_EXCLUDED_QUEUE: async ({ commit }, payload) => {
    let data = await api.StackSystem.addCategoryIntoExcludedQueue(payload);
    // commit("SET_NEW_CATEGORY_IN_EXCLUDED_QUEUE", payload);
    return data;
  },
  EDIT_CATEGORY_ORDER_INTO_EXCLUDED_QUEUE: async ({ commit }, payload) => {
    let data = await api.StackSystem.editCategoryOrderIntoExcludedQueue(
      payload
    );
    // commit("SET_EDITTED_EXCLUDED_QUEUE", payload);
    return data;
  },
  DELETE_EXCLUDED_PARAMETER: async ({ commit }, payload) => {
    let data = await api.StackSystem.deleteExcludedParameterById(payload);
    // commit("DELETE_PARAMETER", payload);
    return data;
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
