/* eslint-disable no-undef, no-unused-vars */
import api from "@/api";
import Vue from "vue";
const name = "USER";
const namespaced = true;
const state = {
  userInfo: [],
  userId: 0,
  allUser: [],
};
const getters = {
  STATE: (state) => {
    return state;
  },
};
const mutations = {
  SET_USER: (state, data) => {
    if (data.type === "success") {
      state.userInfo = data.data;
    }
  },
  SET_DEP_USER: (state, data) => {
    state.userInfo.departments = data;
  },
  SET_USER_ALL: (state, data) => {
    if (data.type === "success") {
      state.allUser = data.data;
    }
  },
  SET_USER_ID: (state, data) => {
    state.userId = data;
  },
  SET_USER_POWER: (state, data) => {
    state.userInfo.powers = data.data;
  },
};
const actions = {
  GET_USER_INFO: async ({ commit }, payload) => {
    let id = await api.User.getId();
    commit("SET_USER_ID", id);
    let data = await api.User.getUserInfo(id);
    commit("SET_USER", data);
    let powers = await api.User.getPowers();
    commit("SET_USER_POWER", powers);
  },
  SET_DEPARTMENT_USER: async ({ commit }, payload) => {
    commit("SET_DEP_USER", payload);
  },
  GET_ALL_USER: async ({ commit }, payload) => {
    let data = await api.User.userAll(payload);
    commit("SET_USER_ALL", data);
  },
  ADD_USER: async ({ commit }, payload) => {
    let data = await api.User.addUser(payload);
    return data;
  },
  GET_USER: async ({ commit }, payload) => {
    let data = await api.User.getUser(payload);
    if (data.type == "success") {
      return data.data;
    }
  },
  EDIT_USER: async ({ commit }, payload) => {
    let data = await api.User.editUser(payload);
    return data;
  },
  DELETE_USER: async ({ commit }, payload) => {
    let data = await api.User.deleteUser(payload);
    return data;
  },
};
export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
