import request from "../core/request";

export default {
  getAllCompany() {
    return request.execute("get", `admin/delivery/company/all`);
  },
  getAllDeliveryTypes() {
    return request.execute("get", "admin/delivery/type/all");
  },
  addCompany(body) {
    return request.execute("post", "admin/delivery/company/add", body);
  },
  getOptions(companyId) {
    return request.execute(
      "get",
      `admin/delivery/options/byCompany/${companyId}`
    );
  },
  getCompanyCity(companyId) {
    return request.execute("get", `admin/delivery/byCompany/city/${companyId}`);
  },
  removeOptionFromCompany(deliveryOptionId) {
    return request.execute(
      "delete",
      `admin/delivery/options/delete/${deliveryOptionId}`
    );
  },
  addOptionToCompany(body) {
    return request.execute("post", `admin/delivery/options/add`, body);
  },
  addCityToCompany(body) {
    return request.execute("post", `admin/delivery/add/company/city`, body);
  },
  deleteCityFromCompany(deliveryCompanyCityId) {
    return request.execute(
      "delete",
      `/admin/delivery/delete/company/city/${deliveryCompanyCityId}`
    );
  },
  addType(body) {
    return request.execute("post", `admin/delivery/type/add`, body);
  },
  editType(body) {
    return request.execute("put", `admin/delivery/type/edit`, body);
  },
  addOptionToDepartment(body) {
    return request.execute("post", `admin/delivery/departmentOption/add`, body);
  },
  removeOptionFromDepartment(departmentOptionId) {
    return request.execute(
      "delete",
      `admin/delivery/departmentOption/delete/${departmentOptionId}`
    );
  },
  getDepartmentOptions(departmentId) {
    return request.execute(
      "get",
      `admin/delivery/departmentOption/get/${departmentId}`
    );
  },
};
