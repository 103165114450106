import api from "@/api";

const name = "FEATURES";
const namespaced = true;
const state = {
  features: [],
  featureGroups: [],
};
const getters = {
  FEATURES: (state) => state.features,
  GROUPS: (state) => state.featureGroups,
};
const mutations = {
  SET_FEATURES: (state, data) => {
    state.features = data;
  },
  SET_GROUPS: (state, data) => {
    state.featureGroups = data;
  },
};
const actions = {
  GET_ALL_FEATURES: async ({ commit }) => {
    let data = await api.Features.getAllFeatures();
    commit("SET_FEATURES", data);
  },
  GET_ALL_GROUPS: async ({ commit }) => {
    let data = await api.Features.getAllGroups();
    commit("SET_GROUPS", data);
  },
};

export default {
  name,
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
