const validate = {
  required: (value) => !!value || "Не оставляйте поле пустым.",
  requiredWithZero: (value) =>
    !!value || value === 0 || "Не оставляйте поле пустым.",
  zero: (v) => v.length > 0 || "Не оставляйте поле пустым.",
  arrRequired(value) {
    if (value.length === 0) {
      return "Не оставляйте поле пустым.";
    }
    return true;
  },
  email(value) {
    if (value != 0) {
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Не правильный email.";
    }
    return true;
  },
  float(value) {
    const patter = /[0-9]/;
    return patter.test(value) || "Введите число";
  },
  min: (v) => {
    if (v?.length) {
      return v.length >= 8 || v.length === 0 || "Минимум 8 символов.";
    }
    return true;
  },
  phoneMin: (v) => {
    if (!v) {
      return "Минимум 11 символов.";
    }
    if (v.length >= 12 || v.length === 0) return true;
    else return "Минимум 11 символов.";
  },
  passMin: (v) => v.length >= 8 || v.length === 0 || "Минимум 8 символов.",
  phoneMin2: (v) => v.length >= 11 || v.length === 0 || "Минимум 11 символов.",
  passMin2: (v) => v.length >= 8 || v.length === 0 || "Минимум 8 символов.",
  minName: (v) => v.length >= 2 || "Минимум 2 символа.",
  minField(v) {
    if (v.length > 0) {
      return v.length <= 4 || "Максимум 4 символа.";
    }
  },
  file(v) {
    if (v.type !== "text/csv") {
      return "только файлы расширения .csv";
    }
  },
};

export default {
  install(Vue) {
    Vue.prototype.$validate = validate;
  },
};
